import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { UrlEnum } from 'util/utils';
import _ from 'lodash';

export default function SelectedCustomerRoute(props) {
    const selectedCustomer = useSelector((store) => store.selectedCustomer);
    const auth = useSelector((store) => store.auth);

    if (!auth) {
        return (
            <Redirect
                to={{ pathname: UrlEnum.LOGIN, state: { login_redirect_url: location.href } }}
            />
        );
    }

    if (!_.isEmpty(selectedCustomer)) {
        return <Route {...props} />;
    } else {
        return <Redirect to={UrlEnum.CUSTOMER_LIST} />;
    }
}

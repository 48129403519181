import React from 'react';

import { merge, cloneDeep } from 'lodash';

import ClickToTop from '../common/ClickToTop';
import { Grid, Box, MenuItem } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import AccountMenuList from 'components/AccountMenuList';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import { logout } from 'actions';

export default function AccountDetail(props) {
    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    const { children } = props;

    if (auth == null || auth === false) {
        return null;
    }

    return (
        <React.Fragment>
            <ThemeProvider
                theme={(outerTheme) =>
                    createTheme(
                        merge(cloneDeep(outerTheme), {
                            components: {
                                MuiButton: {
                                    styleOverrides: {
                                        root: {
                                            borderRadius: 0,
                                        },
                                    },
                                },
                                MuiCardContent: {
                                    styleOverrides: {
                                        root: {
                                            '&:last-child': {
                                                paddingBottom: 12,
                                            },
                                        },
                                    },
                                },
                            },
                            typography: {
                                body1: {
                                    [outerTheme.breakpoints.down('md')]: { fontSize: 15 },
                                    [outerTheme.breakpoints.down('sm')]: {
                                        fontSize: 14,
                                    },
                                },
                            },
                            palette: {
                                primary: {
                                    main: '#777777',
                                },
                                border: { primary: '#ddd' },
                                orderHeader: { primary: '#FCFCFD' },
                            },
                        }),
                    )
                }
            >
                <Grid container direction="row" justifyContent="center" sx={{ padding: 2 }}>
                    <Grid item display={{ xs: 'none', lg: 'block' }}>
                        <AccountMenuList
                            renderLink={(url, text, index) => {
                                return (
                                    <NavLink
                                        key={`menuLink-${index}`}
                                        activeClassName="active-menuLink"
                                        to={url}
                                    >
                                        <MenuItem sx={{ color: 'black' }}>{text}</MenuItem>
                                    </NavLink>
                                );
                            }}
                        />
                        <NavLink to="#" onClick={(e) => handleLogout(e)}>
                            <MenuItem sx={{ color: 'black' }}>{'Log out'}</MenuItem>
                        </NavLink>
                    </Grid>
                    <Grid item xs={12} lg={10} xl={8}>
                        <Box
                            sx={{
                                paddingBottom: { xs: '30px' },
                                pl: 1,
                            }}
                        >
                            {children}
                        </Box>
                    </Grid>
                </Grid>
                <ClickToTop />
            </ThemeProvider>
        </React.Fragment>
    );
}

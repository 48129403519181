import React, { Component } from 'react';
import { isMobile, scrollToTop } from 'util/utils';

import { Tooltip, Box } from '@mui/material';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import PavoButton from 'components/Button/PavoButton';

class ClickToTop extends Component {
    constructor(props) {
        super(props);

        this.handleScroll = this.handleScroll.bind(this);

        this.state = {
            shouldShow: false,
        };
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (document.documentElement.scrollTop > 500) {
            this.setState({ shouldShow: true });
        } else {
            this.setState({ shouldShow: false });
        }
    }

    clickToTop() {
        scrollToTop();
    }

    render() {
        if (isMobile) return null;
        const { shouldShow } = this.state;
        return (
            <React.Fragment>
                {shouldShow && (
                    <Tooltip title="Back to Top" placement="left-start">
                        <Box
                            sx={{
                                position: 'fixed',
                                width: '35px',
                                height: '35px',
                                bottom: '100px',
                                right: '40px',
                            }}
                        >
                            <PavoButton
                                color="primary"
                                size="large"
                                onClick={() => this.clickToTop()}
                                icon={<KeyboardDoubleArrowUpIcon />}
                            />
                        </Box>
                    </Tooltip>
                )}
            </React.Fragment>
        );
    }
}

export default ClickToTop;

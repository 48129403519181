import { useSelector } from 'react-redux';
import {
    isB2BWebsite,
    isCustomerSelected,
    isUsingPayments,
    siteAllowsOrdering,
    UrlEnum,
} from 'util/utils';

export default function AccountMenuList({ renderLink }) {
    const auth = useSelector((state) => state.auth);
    const websiteConfiguration = useSelector((state) => state.websiteConfiguration);
    const serviceType = useSelector((state) => state.serviceType);

    const menuLinks = [];

    if (isB2BWebsite(serviceType)) {
        menuLinks.push({ url: UrlEnum.ACCOUNT_PROFILE, text: 'Company Information' });
    } else {
        menuLinks.push({ url: UrlEnum.ACCOUNT_DASHBOARD, text: 'Dashboard' });
        menuLinks.push({ url: UrlEnum.CUSTOMER_LIST, text: 'Customers' });
    }

    menuLinks.push(
        ...[
            {
                url: UrlEnum.ORDER_HISTORY,
                text: 'Orders',
            },
            {
                url: UrlEnum.INVOICE_HISTORY,
                text: 'Invoices',
            },
        ],
    );

    if (isUsingPayments(websiteConfiguration) && isCustomerSelected(auth)) {
        menuLinks.push(
            ...[
                {
                    url: UrlEnum.PAYMENT_HISTORY,
                    text: 'Payments',
                },
                {
                    url: UrlEnum.PAYMENT_METHODS,
                    text: 'Payment Methods',
                },
            ],
        );
    }

    if (siteAllowsOrdering(websiteConfiguration)) {
        menuLinks.push({ url: UrlEnum.RESERVED_CART, text: 'Reserved List' });
    }
    menuLinks.push(
        ...[
            { url: UrlEnum.KEYBOARD_SHORTCUTS, text: 'Keyboard Shortcuts' },
            {
                url: UrlEnum.ACCOUNT_SETTINGS,
                text: 'Account Settings',
            },
        ],
    );

    if (!renderLink) {
        return null;
    }

    return menuLinks.map(({ url, text }, index) => {
        return renderLink(url, text, index);
    });
}

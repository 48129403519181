import React from 'react';
import { useSelector } from 'react-redux';
import { UrlEnum, textColorForBackgroundColorHex } from 'util/utils';
import _ from 'lodash';
import { Box } from '@mui/material';
import { useHistory } from 'react-router';

export default function LogoImage(props) {
    const history = useHistory();
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const { header_color, logo_image } = websiteConfiguration;
    const color = textColorForBackgroundColorHex(header_color);
    const useDarkLogo = color === 'white';

    let logoImage = null;
    if (useDarkLogo && !_.isEmpty(logo_image.dark)) {
        logoImage = logo_image.dark.medium;
    } else {
        logoImage = logo_image.medium;
    }

    return (
        <Box
            onClick={() => history.push(UrlEnum.HOME)}
            sx={{
                cursor: 'pointer',
                height: { xs: '44px', md: '56px' },
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <img src={logoImage} alt="logo" style={{ maxHeight: '100%', width: 'auto' }} />
        </Box>
    );
}

import axios from 'axios';
import _ from 'lodash';
import configuration from 'network/configuration';

class WebsocketClient {
    alertHandler;
    notificationHandler;
    socket = null;
    userID = null;
    successConnection = false; // Connected and authenticated

    constructor(notificationHandler, alertHandler) {
        this.notificationHandler = notificationHandler;
        this.alertHandler = alertHandler;
    }

    async sendMessage(dataObj) {
        this.socket.onopen = function () {
            this.socket.send(JSON.stringify(dataObj));
        };
    }

    _notify(requestName, message, data, error) {
        if (this.notificationHandler) {
            this.notificationHandler(requestName, message, data, error);
        }
    }

    _alertUser(message) {
        if (this.alertHandler) {
            this.alertHandler(message);
        }
    }
    _alertClosed() {
        this._alertUser(
            'The connection to the server was closed. Please refresh the page to reconnect.',
        );
    }

    isOpen(ws) {
        return ws.readyState === ws.OPEN;
    }

    async _getAndCheckCredentials() {
        try {
            const backendSettings = configuration.fetchWebsiteObject({});
            const result = await axios.get('/api/mercury/web_sockets', backendSettings);
            const { data } = result;

            const { token } = data;
            let { url } = data;

            if (!url || !token) return;
            // Backend check might not be correct
            if (location.protocol === 'https:') {
                url = url.replace('ws://', 'wss://');
                data.url = url;
            }
            return data;
        } catch (error) {
            // do nothing
            return;
        }
    }

    async connectWS(userID = '') {
        if (this.socket) {
            try {
                this.socket.close();
            } catch (error) {
                // do nothing
            }
        }
        this.successConnection = false;
        if (!_.isEmpty(userID)) {
            this.userID = userID;
        }

        if (!this.userID) {
            return;
        }

        const data = await this._getAndCheckCredentials();
        if (_.isEmpty(data)) return;

        const { token, comp_id, url } = data;

        try {
            // username is the convention used for WS by all systems
            const socket = new WebSocket(
                `${url}?token=${token}&username=${this.userID}&comp_id=${comp_id}`,
            );
            this.socket = socket;

            socket.onopen = () => {
                // Authenticated by backend with token and user ID
                this.successConnection = true;
            };

            socket.onerror = () => {
                // do nothing
                return;
            };

            socket.onclose = () => {
                if (this.successConnection) {
                    this.connectWS();
                }
            };

            socket.onmessage = (event) => {
                const { data } = event;
                try {
                    const parseData = JSON.parse(data);

                    const { message, requestName, data: messageData, error } = parseData;

                    if (error) {
                        this._notify(requestName, null, null, error);
                    } else if (message || messageData) {
                        this._notify(requestName, message, messageData);
                    }
                } catch (e) {
                    console.error(e);
                }
            };
        } catch (error) {
            console.error(error);
            return;
        }
    }

    async sendRequest(requestBody, errorHandler) {
        try {
            if (!this.socket || !this.isOpen(this.socket)) {
                await this.connectWS();
                // Need to wait for connection to complete
                setTimeout(() => {
                    if (this.socket && this.isOpen(this.socket)) {
                        this.socket.send(JSON.stringify(requestBody));
                    }
                }, 5000);
            } else {
                this.socket.send(JSON.stringify(requestBody));
            }
        } catch (e) {
            console.error(e);
            errorHandler();
        }
    }
}

export default WebsocketClient;

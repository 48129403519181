import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export default function PavoModal(props) {
    const { affirmativeActionColor = 'primary' } = props;
    const cancelTitle = props.cancelText ? props.cancelText : 'Cancel';

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            {props.title && (
                <DialogTitle>
                    <Typography>{props.title}</Typography>
                </DialogTitle>
            )}
            <DialogContent>
                <Typography>{props.body}</Typography>
            </DialogContent>
            <DialogActions>
                {props.onCancelAction != null && (
                    <Button
                        disabled={props.affirmativeLoading}
                        onClick={() => props.onCancelAction()}
                    >
                        {cancelTitle}
                    </Button>
                )}
                {props.additionalAction != null && props.additionalAction}
                {props.onAffirmativeAction != null && (
                    <LoadingButton
                        loading={props.affirmativeLoading}
                        variant="contained"
                        color={affirmativeActionColor}
                        onClick={() => props.onAffirmativeAction()}
                    >
                        {props.affirmativeText ?? 'Confirm'}
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
}

PavoModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    cancelTitle: PropTypes.string,
    onCancelAction: PropTypes.func,
    affirmativeText: PropTypes.string,
    onAffirmativeAction: PropTypes.func,
    affirmativeLoading: PropTypes.bool,
    affirmativeActionColor: PropTypes.string,
    additionalAction: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

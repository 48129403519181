import { FETCH_CART_ITEMS, FETCH_CART_ITEMS_COUNT } from '../constants/ActionTypes';

export default function cartReducer(state = null, action) {
    switch (action.type) {
        case FETCH_CART_ITEMS:
            return {
                ...state,
                cart_items: action.cart,
            };

        case FETCH_CART_ITEMS_COUNT:
            return {
                ...state,
                count: action.payload,
            };
        default:
            return state;
    }
}

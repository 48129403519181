import React from 'react';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga4';

export default function PavoHelmet(props) {
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const { title, allowSEO, description } = props;

    const { company_name, analytics = {} } = websiteConfiguration;

    const history = useHistory();

    history.listen((location) => {
        if (analytics.google != null) {
            // Google analytics is initialized in App.js
            ReactGA.set({ path: location.pathname });
            ReactGA.send({ hitType: 'pageview', page: location.pathname });
        }
    });

    if (!websiteConfiguration) return null;

    return (
        <Helmet>
            <title>{`${title} | ${company_name} `}</title>
            {allowSEO && (
                <React.Fragment>
                    {description ? (
                        <meta
                            name="description"
                            content={`${description},${websiteConfiguration.seo_keyword}`}
                        />
                    ) : (
                        <meta
                            name="description"
                            content={`${websiteConfiguration.seo_description},${websiteConfiguration.seo_keyword}`}
                        />
                    )}
                    <meta name="keywords" content={websiteConfiguration.seo_keyword} />
                </React.Fragment>
            )}
        </Helmet>
    );
}

import React from 'react';

import { Typography } from '@mui/material';
import { isEmpty } from 'lodash';

// Returns it as list of components of typography
const getAddressComponent = (storeAddress) => {
    const { address, address2, city, state, zip, country } = storeAddress;

    const addressRows = [];

    if (!isEmpty(address)) {
        addressRows.push(<Typography>{address}</Typography>);
    }

    if (!isEmpty(address2)) {
        addressRows.push(<Typography>{address2}</Typography>);
    }

    if (city || state || zip || country) {
        let str = '';
        if (city) {
            str += city;
        }
        if (state) {
            if (city) str += `, ${state}`;
            else str += state;
        }

        if (zip) {
            if (city || state) str += ` ${zip}`;
            else str += zip;
        }

        if (country) {
            if (city || state || zip) str += ` ${country}`;
            else str += country;
        }

        addressRows.push(<Typography>{str}</Typography>);
    }

    return addressRows;
};

export { getAddressComponent };

import { FETCH_ENABLED_SERVICE } from '../constants/ActionTypes';

export default function websiteReducer(state = null, action) {
    switch (action.type) {
        case FETCH_ENABLED_SERVICE:
            return action.payload || false;
        default:
            return state;
    }
}

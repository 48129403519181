import React from 'react';
import { Box } from '@mui/material';

function PavoContent({ children, restSX }) {
    // Set padding in xl if width > 1400
    let xlPadding = 0;
    if (restSX?.maxWidth || restSX?.maxWidth?.xl) {
        const widthString = restSX.maxWidth.xl
            ? String(restSX.maxWidth.xl)
            : String(restSX.maxWidth);
        const result = widthString.match(/(\d+)/);
        if (result && Number(result[0]) > 1400) {
            xlPadding = '24px';
        }
    }
    return (
        <Box
            sx={{
                pl: { xs: '4px', sm: '12px', md: '24px', lg: '24px', xl: xlPadding },
                pr: { xs: '4px', sm: '12px', md: '24px', lg: '24px', xl: xlPadding },
                pt: { xs: '30px', md: '24px' },
                pb: { xs: '100px', md: '200px' },
                maxWidth: '1600px',
                marginLeft: 'auto',
                marginRight: 'auto',
                minHeight: '600px',
                ...restSX,
            }}
        >
            {children}
        </Box>
    );
}

export default PavoContent;

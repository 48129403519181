import axios from 'axios';
import configuration from './configuration';

export const fetchMenubars = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/menubars', object);
        return response.data;
    } catch (error) {
        console.error('Fetching menubar method Get Error: ', error);
        return [];
    }
};

// #region for marketing emails
export const submitEmailAddress = async (emailAddress) => {
    const object = configuration.fetchWebsiteObject();
    return axios.post('/api/email', { email: emailAddress }, object);
};

// #endregion

// #region faq page content
export const fetchFAQContent = async () => {
    const object = configuration.fetchWebsiteObject();
    const response = await axios.get('/api/faq_content', object);
    return response.data;
};

// #region sending email from contact us page
export const sendEmail = async (emailObject) => {
    try {
        const object = configuration.fetchWebsiteObject();
        return axios.post('/api/sendemail', emailObject, object);
    } catch (error) {
        console.error('Email delivery failed : ', error);
        throw error;
    }
};

export const updateAgreeTerms = async (params = {}) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/update_agree_terms', params, object);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

// #endregion

// #region maps token
export const fetchMapsToken = async () => {
    const object = configuration.fetchWebsiteObject();
    const response = await axios.get('/api/maps_token', object);
    return response.data.token;
};

// #endregion

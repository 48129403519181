import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from 'components/images/logo';
import SearchTextField from './SearchTextField';
import ScienceIcon from '@mui/icons-material/Science';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
    UrlEnum,
    textColorForBackgroundColorHex,
    isPriceCheckerWebsite,
    isProduction,
    siteAllowsOrdering,
    isSalesRepWebsite,
} from 'util/utils';

import {
    AppBar,
    Fab,
    Box,
    Menu,
    MenuItem,
    Toolbar,
    IconButton,
    Typography,
    Badge,
    Tooltip,
    Avatar,
} from '@mui/material';
import SelectCustomerID from 'components/Button/SelectCustomerID';
import { handleSearchKeyword, handleSearchProduct } from 'util/search';
import { isEmpty } from 'lodash';
import AccountMenuList from 'components/AccountMenuList';

function Header(props) {
    const websiteConfiguration = useSelector((state) => state.websiteConfiguration);
    const auth = useSelector((state) => state.auth);
    const serviceType = useSelector((state) => state.serviceType);

    const [anchorElAccountSettings, setAnchorElAccountSettings] = useState(null);

    const { cartCount } = props;

    const textColor = textColorForBackgroundColorHex(websiteConfiguration.header_color);

    const history = useHistory();

    const renderAccountMenu = () => {
        const username = isSalesRepWebsite(serviceType) ? auth.oms_login : auth.username;

        return (
            <Menu
                open={Boolean(anchorElAccountSettings)}
                anchorEl={anchorElAccountSettings}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAnchorElAccountSettings(null);
                }}
            >
                <MenuItem sx={{ color: 'black', ml: 1, borderBottom: '2px solid lightgray' }}>
                    <Box display="flex" flexDirection={'column'}>
                        <Typography>{auth.customer_name}</Typography>
                        <Typography color="textSecondary">{username}</Typography>
                    </Box>
                </MenuItem>
                <AccountMenuList
                    renderLink={(url, text, index) => {
                        return (
                            <NavLink
                                key={`menuLink-${index}`}
                                activeClassName="active-menuLink"
                                onClick={() => setAnchorElAccountSettings(null)}
                                to={url}
                            >
                                <MenuItem sx={{ ml: 1, color: 'black' }}>{text}</MenuItem>
                            </NavLink>
                        );
                    }}
                />
                <NavLink to="#" onClick={(e) => props.onLogout()}>
                    <MenuItem sx={{ color: 'black', ml: 1 }}>{'Log out'}</MenuItem>
                </NavLink>
            </Menu>
        );
    };

    const renderAccountButton = () => {
        let icon = null;
        if (!auth) {
            icon = <AccountCircleIcon />;
        } else {
            const { customer_name } = auth;
            if (isEmpty(customer_name)) {
                icon = <AccountCircleIcon />;
            } else {
                const splitName = customer_name.split(' ');
                let initials = '';
                if (splitName.length > 1) {
                    initials = splitName[0].charAt(0) + splitName[1].charAt(0);
                } else {
                    initials = splitName[0].charAt(0);
                }
                icon = <Avatar>{initials}</Avatar>;
            }
        }
        return (
            <IconButton
                id="account-button"
                size="small"
                onClick={(event) => {
                    if (auth) {
                        setAnchorElAccountSettings(event.target);
                    } else {
                        history.push(UrlEnum.LOGIN, { login_redirect_url: location.href });
                    }
                }}
            >
                {icon}
            </IconButton>
        );
    };

    const renderCart = () => {
        if (!auth || !siteAllowsOrdering(websiteConfiguration)) {
            return null;
        }

        return (
            <IconButton
                id="shopping-cart-button"
                onClick={() => {
                    history.push(UrlEnum.SHOPPING_CART);
                }}
            >
                <Badge badgeContent={cartCount} color="error">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
        );
    };

    const showCompanyName = websiteConfiguration.display_company_name_after_logo;
    const priceCheckerSite = isPriceCheckerWebsite(serviceType);
    const checkoutPage = window.location.pathname === UrlEnum.CHECKOUT;
    const loginPage = window.location.pathname === UrlEnum.LOGIN;
    const displaySearchBar = !priceCheckerSite && !checkoutPage && !loginPage;

    const renderCompanyName = () => {
        if (!showCompanyName) {
            return null;
        }

        const { company_name, tagline } = websiteConfiguration;
        let variant = 'h4';
        let text = company_name;
        if (!isEmpty(tagline)) {
            text += ` | ${tagline}`;
            variant = 'h5';
        }

        return (
            <Box sx={{ marginRight: 4, fontWeight: 700 }}>
                <Link to="/">
                    <Typography variant={variant}>{text}</Typography>
                </Link>
            </Box>
        );
    };

    return (
        <AppBar
            position={priceCheckerSite ? 'fixed' : 'relative'}
            elevation={0}
            sx={{
                backgroundColor: websiteConfiguration.header_color,
                color: textColor,
                minHeight: 45,
                paddingTop: priceCheckerSite ? '10px' : '12px',
                paddingBottom: priceCheckerSite ? '5px' : '8px',
                borderBottom:
                    checkoutPage || loginPage || !siteAllowsOrdering(websiteConfiguration)
                        ? `10px solid ${websiteConfiguration.topbar_color}`
                        : 'none',
            }}
        >
            <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        maxWidth: '1600px',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ marginRight: 4 }}>
                            <Logo />
                        </Box>
                        {!isProduction() && (
                            <Tooltip title="DEVELOPMENT">
                                <Fab color="warning" size="small" disableRipple={true}>
                                    <ScienceIcon fontSize="small" />
                                </Fab>
                            </Tooltip>
                        )}
                        {renderCompanyName()}
                        <SelectCustomerID />
                    </Box>
                    {displaySearchBar && (
                        <>
                            <Box sx={{ flexGrow: 1 }} />
                            {siteAllowsOrdering(websiteConfiguration) && (
                                <SearchTextField
                                    placeholder="Search [Ctrl + K]"
                                    onSearchKeyword={(keyword) => {
                                        handleSearchKeyword(history, keyword);
                                    }}
                                    onSelectProduct={(itemNum) => {
                                        handleSearchProduct(history, itemNum);
                                    }}
                                />
                            )}
                            {renderAccountButton()}
                            {renderAccountMenu()}
                            {renderCart()}
                        </>
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

Header.propTypes = {
    onLogout: PropTypes.func.isRequired,
    cartCount: PropTypes.number,
};

export default Header;

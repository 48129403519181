import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableSortLabel } from '@mui/material';

function PavoHeaderCell(props) {
    const { tableCellProps = {}, sortLabelProps = {}, sortable = false, children } = props;
    if (!sortable) {
        return <TableCell {...tableCellProps}>{children}</TableCell>;
    }

    return (
        <TableCell {...tableCellProps}>
            <TableSortLabel {...sortLabelProps}>{children}</TableSortLabel>
        </TableCell>
    );
}

PavoHeaderCell.propTypes = {
    sortable: PropTypes.bool,
    tableCellProps: PropTypes.object,
    sortLabelProps: PropTypes.object,
    children: PropTypes.any.isRequired,
};

export default PavoHeaderCell;

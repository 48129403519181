import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import {
    isB2BWebsite,
    siteAccessRestrictedDueToNoLogin,
    siteAllowsOrdering,
    UrlEnum,
} from 'util/utils';

export default function RestrictSiteAccessRoute(props) {
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const auth = useSelector((store) => store.auth);
    const serviceType = useSelector((store) => store.serviceType);

    if (!siteAllowsOrdering(websiteConfiguration) && auth) {
        if (isB2BWebsite(serviceType)) {
            return <Redirect to={UrlEnum.ACCOUNT_PROFILE} />;
        } else {
            return <Redirect to={UrlEnum.CUSTOMER_LIST} />;
        }
    } else if (siteAccessRestrictedDueToNoLogin(websiteConfiguration, auth)) {
        return (
            <Redirect
                to={{ pathname: UrlEnum.LOGIN, state: { login_redirect_url: location.href } }}
            />
        );
    } else {
        return <Route {...props} />;
    }
}

import * as types from '../constants/ActionTypes';

const filtersReducerDefaultState = null;

const filtersReducer = (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case types.FETCH_FILTERS:
            return {
                ...state,
                filters: action.payload,
            };
        default:
            return state;
    }
};

export default filtersReducer;

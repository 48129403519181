import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import WebFont from 'webfontloader';
import { CssBaseline } from '@mui/material';
import { lighten } from '@mui/system';
import { lightenDarkenColor, textColorForBackgroundColorHex } from 'util/utils';

export default function PavoTheme(props) {
    const { font_family, primary_color, secondary_color, background_color } = useSelector(
        (store) => store.websiteConfiguration,
    );

    WebFont.load({
        google: {
            families: [`${font_family}:300,400,700,900`, 'sans-serif'],
        },
    });

    const primaryTextColor = textColorForBackgroundColorHex(background_color);

    const textColorForBackground = textColorForBackgroundColorHex(primary_color);
    const darkenedColor = lightenDarkenColor(primary_color, -30);
    let theme = createTheme();
    theme = createTheme(theme, {
        typography: {
            fontFamily: font_family,
            body1: {
                lineHeight: 1.3,
                letterSpacing: '0.00938em',
                fontSize: '14px',
                // This is to prevent iOS zooming in when you select an input
                [theme.breakpoints.down('md')]: { fontSize: '16px' },
            },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: 'none',
                        color: primary_color,
                        '&:hover': {
                            color: darkenedColor,
                        },
                    },
                    contained: {
                        backgroundColor: primary_color,
                        color: textColorForBackground,
                        '&:hover': {
                            backgroundColor: darkenedColor,
                            color: textColorForBackground,
                        },
                    },
                },
                defaultProps: {
                    disableRipple: true,
                    size: 'small',
                },
            },
            MuiIconButton: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiTable: {
                defaultProps: {
                    size: 'small',
                    border: '1px solid',
                    borderColor: '#ddd',
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderLeft: '1px solid',
                        borderColor: '#ddd',
                    },
                },
            },
            MuiBreadcrumbs: {
                styleOverrides: {
                    root: {
                        paddingBottom: '20px',
                    },
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {
                        color: primaryTextColor,
                    },
                },
            },
            MuiCard: {
                defaultProps: {
                    variant: 'outlined',
                },
            },
            MuiChip: {
                defaultProps: {
                    size: 'small',
                },
            },
            MuiTabs: {
                styleOverrides: {
                    root: {
                        backgroundColor: lighten('#607D8B', 0.85),
                        borderRadius: 10,
                        minHeight: 44,
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    size: 'small',
                },
            },
        },
        palette: {
            primary: { main: primary_color },
            secondary: { main: secondary_color },
            grayedOut: { primary: '#8f8f8f' },
            border: { primary: '#ddd' },
            background: {
                default: background_color,
            },
            promotionText: {
                primary: ' #17a2b8',
            },
            sizeTable: { primary: '#FCFCFD' },
            textColor: { main: textColorForBackground },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {props.children}
        </ThemeProvider>
    );
}

import {
    FETCH_PREPACK_RUN,
    FETCH_DEFINITIONS,
    FETCH_PRODUCT_DETAIL_COLOR,
    FETCH_PRODUCT_DETAIL_RUN,
    FETCH_PRODUCT_DETAIL_WAREHOUSE,
} from '../constants/ActionTypes';

import _ from 'lodash';

const initialState = {
    definitions: null,
    prepackrun: [],
    product_detail_color: '',
    product_detail_run: '',
    product_detail_warehouse_code: '',
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_PREPACK_RUN: {
            const prepackRuns = action.payload ?? [];
            const existingPrepacks = _.cloneDeep(state.prepackrun);

            // Add existing prepacks
            const newPrepackClasses = new Set();
            prepackRuns.forEach((prepackObj) => {
                newPrepackClasses.add(prepackObj.class_cd);
            });
            existingPrepacks.forEach((prepackObj) => {
                if (!newPrepackClasses.has(prepackObj.class_cd)) {
                    prepackRuns.push(prepackObj);
                }
            });

            return {
                ...state,
                prepackrun: prepackRuns,
            };
        }
        case FETCH_DEFINITIONS:
            return {
                ...state,
                definitions: action.payload,
            };
        case FETCH_PRODUCT_DETAIL_COLOR:
            return {
                ...state,
                product_detail_color: action.payload,
            };
        case FETCH_PRODUCT_DETAIL_RUN:
            return {
                ...state,
                product_detail_run: action.payload,
            };
        case FETCH_PRODUCT_DETAIL_WAREHOUSE:
            return {
                ...state,
                product_detail_warehouse_code: action.payload,
            };
        default:
            return state;
    }
};

export default productReducer;

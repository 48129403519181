import { cloneDeep } from 'lodash';
import { ADD_WS_MSG, CLEAR_WS_MSG } from '../constants/ActionTypes';

export default function websiteReducer(state = { messages: {} }, action) {
    switch (action.type) {
        case ADD_WS_MSG: {
            const { requestName, message, data, error } = action.payload;

            const newMessages = cloneDeep(state.messages);

            if (requestName) {
                newMessages[requestName] = { message, data, error };
            }

            return { ...state, messages: newMessages };
        }
        case CLEAR_WS_MSG: {
            const { requestName } = action.payload;

            const newMessages = cloneDeep(state.messages);

            if (requestName) {
                delete newMessages[requestName];
            }

            return { ...state, messages: newMessages };
        }
        default: {
            return state;
        }
    }
}

import { FETCH_WEBSITE_CONFIG } from '../constants/ActionTypes';

export default function websiteReducer(state = null, action) {
    switch (action.type) {
        case FETCH_WEBSITE_CONFIG:
            return action.payload || false;
        default:
            return state;
    }
}

import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { formattedPrice } from '@omsmastersystem/pavo-intl';

import { useHistory } from 'react-router-dom';
import {
    isB2BWebsite,
    UrlEnum,
    siteAccessRestrictedDueToNoLogin,
    textColorForBackgroundColorHex,
    isLoggedIn,
    siteAccessPartiallyRestrictedDueToNoLogin,
    isSalesRepWebsite,
} from 'util/utils';
import {
    AppBar,
    ListItem,
    ListItemText,
    ListItemButton,
    IconButton,
    Drawer,
    Collapse,
    Box,
    Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import SubmenuListItem from 'components/HeaderBar/TopBar/SubmenuListItem';
import PavoButton from 'components/Button/PavoButton';
import CategoryList from 'components/list/CategoryList';

function TopBarResponsive(props) {
    const auth = useSelector((store) => store.auth);
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const serviceType = useSelector((store) => store.serviceType);
    const category = useSelector((store) => store.category);
    const pavoGlobalFilter = useSelector((store) => store.pavoGlobalFilter);

    const [openFields, setOpenFields] = React.useState(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);

    const { menuList } = props;

    const { categories } = category;
    const history = useHistory();

    const backgroundColor = websiteConfiguration.topbar_color;
    const textColor =
        websiteConfiguration.topbar_font_color ?? textColorForBackgroundColorHex(backgroundColor);

    const renderCategoryMenuItem = (menu, index) => {
        if (_.isEmpty(categories)) return null;

        const category = categories.find((el) => el.category_id === menu.id.toLowerCase());

        if (_.isEmpty(category)) {
            return null;
        }

        const categoriesMenuIndex = `categoryMenu-${index}`;
        const open = openFields === categoriesMenuIndex;

        const rootCategories = categories.filter(
            (el) => el.parent_category_id === category.category_id,
        );

        const openFieldIndex = `category_${index}`;

        const id = openFields === openFieldIndex ? 'categories-popover' : undefined;

        return (
            <React.Fragment key={`${menu.id}-responsive-${index}`}>
                <ListItemButton
                    aria-describedby={id}
                    onClick={(event) => {
                        if (!open) {
                            setOpenFields(categoriesMenuIndex);
                        } else {
                            setOpenFields(null);
                        }
                    }}
                >
                    <ListItemText
                        primary={menu.name || category.title}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {rootCategories.map((subcategory, subCategoriesIndex) => {
                        return (
                            <ListItem
                                key={`${categoriesMenuIndex}-${subCategoriesIndex}`}
                                button
                                sx={{ pl: 4 }}
                                onClick={() => {
                                    history.push(
                                        `${UrlEnum.PRODUCTS}?category=${encodeURIComponent(
                                            subcategory.slug,
                                        )}`,
                                    );
                                    setOpenFields(null);
                                    setOpenDrawer(false);
                                }}
                            >
                                <ListItemText primary={subcategory.title} />
                            </ListItem>
                        );
                    })}
                </Collapse>
            </React.Fragment>
        );
    };

    const renderCategoryList = () => {
        const { show_category_in_menubar } = websiteConfiguration.category_settings;
        if (!show_category_in_menubar || !isB2BWebsite(serviceType) || _.isEmpty(categories)) {
            return null;
        }

        const id = openFields === 'category_list' ? 'categories-popover' : undefined;

        const open = openFields === 'category_list';

        return (
            <React.Fragment>
                <ListItemButton
                    aria-describedby={id}
                    onClick={(event) => {
                        if (!open) {
                            setOpenFields('category_list');
                        } else {
                            setOpenFields(null);
                        }
                    }}
                >
                    <ListItemText
                        primary={'Shop by Category'}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <CategoryList queryFilter={{}} />
                </Collapse>
            </React.Fragment>
        );
    };

    const goLinkAndCloseOpenFields = (url) => {
        setOpenDrawer(null);
        history.push(url);
    };

    const renderSubmenu = (submenu, menu, showHeader) => {
        let hasSub = _.isEmpty(submenu.subitems) ? false : true;

        function getUrl(items) {
            const subqueries = [];
            for (const key in items) {
                const value = items[key];
                subqueries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
            return `${UrlEnum.PRODUCTS}?${subqueries.join('&')}`;
        }
        // Populate subitemsList
        let subitemsList = [];
        if (hasSub) {
            if (submenu.id === 'PRICE') {
                const prices = pavoGlobalFilter.filters.find((filter) => filter.name === 'price');
                if (!_.isEmpty(prices?.values)) {
                    let prevPriceTo;
                    prices.values.forEach((priceObj, index) => {
                        const { price_to, price_from } = priceObj;
                        prevPriceTo = price_to;
                        subitemsList.push(
                            <SubmenuListItem
                                showHeader={showHeader}
                                key={`${menu.name}-${submenu.name}-prices-${index}`}
                                onClick={() =>
                                    goLinkAndCloseOpenFields(getUrl({ price_to, price_from }))
                                }
                                text={`${formattedPrice(price_from)} to ${formattedPrice(
                                    price_to,
                                )}`}
                            />,
                        );
                    });
                    subitemsList.push(
                        <SubmenuListItem
                            showHeader={showHeader}
                            onClick={() =>
                                goLinkAndCloseOpenFields(getUrl({ price_from: prevPriceTo }))
                            }
                            text={`${formattedPrice(prevPriceTo)}+`}
                        />,
                    );
                }
            } else {
                subitemsList = submenu.subitems?.map((item, index) => {
                    return (
                        <SubmenuListItem
                            showHeader={showHeader}
                            onClick={() =>
                                goLinkAndCloseOpenFields(
                                    getUrl({ [submenu.id.toLowerCase()]: item.name }),
                                )
                            }
                            key={`${item.name}-submenu-responsive-${index}`}
                            text={item.description ? item.description : item.name}
                        />
                    );
                });
            }
        }

        return (
            <div key={submenu.name}>
                {showHeader && (
                    <ListItem
                        sx={{ pl: 4 }}
                        button={!hasSub}
                        onClick={(event) => {
                            if (!hasSub) {
                                history.push(
                                    `${UrlEnum.PRODUCTS}?${encodeURIComponent(
                                        menu.id.toLowerCase(),
                                    )}=${encodeURIComponent(submenu.name)}`,
                                );
                                setOpenDrawer(false);
                            }
                        }}
                    >
                        <ListItemText
                            primary={submenu.name}
                            primaryTypographyProps={{ fontWeight: 'bold' }}
                        />
                    </ListItem>
                )}
                {hasSub && subitemsList}
            </div>
        );
    };

    const renderMenuItem = (menu, index) => {
        let hasSub = _.isEmpty(menu.subitems) ? false : true;
        let toLink = null;
        if (menu.slug) {
            toLink = `${UrlEnum.PRODUCTS}?slug=${encodeURIComponent(menu.slug)}`;
        } else if (menu.link) {
            let extLink = menu.link;
            if (!extLink.startsWith('http://') && !extLink.startsWith('https://')) {
                extLink = `https://${extLink}`;
            }
            toLink = { pathname: extLink };
        } else {
            toLink = '#';
        }

        const menuListIndex = `menuList-responsive-${index}`;

        const id = openFields === menuListIndex ? 'categories-popover' : undefined;

        const open = openFields === menuListIndex;

        return (
            <React.Fragment key={menuListIndex}>
                <ListItemButton
                    aria-describedby={id}
                    onClick={(event) => {
                        if (menu.link) {
                            window.open(toLink.pathname);
                            setOpenDrawer(false);
                        } else if (!open) {
                            setOpenFields(menuListIndex);
                        } else {
                            setOpenFields(null);
                        }
                    }}
                >
                    <ListItemText
                        primary={menu.name}
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                    {hasSub && (open ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    {hasSub &&
                        menu.subitems.map((submenu, vl_index) => {
                            const length = menu.subitems.length;
                            if (submenu.slug) {
                                return (
                                    <ListItem
                                        key={`${submenu.slug}-reponsive-${vl_index}`}
                                        button
                                        sx={{ pl: 4 }}
                                        onClick={() => {
                                            history.push(
                                                `${UrlEnum.PRODUCTS}?slug=${encodeURIComponent(
                                                    submenu.slug,
                                                )}`,
                                            );
                                            setOpenFields(null);
                                            setOpenDrawer(false);
                                        }}
                                    >
                                        <ListItemText primary={submenu.name} />
                                    </ListItem>
                                );
                            }
                            if (submenu.link) {
                                if (
                                    !submenu.link.startsWith('http://') &&
                                    !submenu.link.startsWith('https://')
                                ) {
                                    submenu.link = `https://${submenu.link}`;
                                }
                                return (
                                    <ListItem
                                        key={vl_index}
                                        button
                                        sx={{ pl: 4 }}
                                        onClick={() => {
                                            window.open(submenu.link);
                                            setOpenFields(null);
                                            setOpenDrawer(false);
                                        }}
                                    >
                                        <ListItemText primary={submenu.name} />
                                    </ListItem>
                                );
                            }
                            return renderSubmenu(submenu, menu, length > 1);
                        })}
                </Collapse>
            </React.Fragment>
        );
    };

    const renderMenuList = () => {
        if (isSalesRepWebsite(serviceType)) {
            return null;
        }

        if (_.isEmpty(menuList)) {
            return null;
        }

        return menuList.map((menu, index) => {
            if (menu.type === 'Category') {
                return renderCategoryMenuItem(menu, index);
            } else {
                return renderMenuItem(menu, index);
            }
        });
    };

    const container = props.window !== undefined ? () => window().document.body : undefined;

    const shouldDisplayProducts =
        !siteAccessRestrictedDueToNoLogin(websiteConfiguration, auth) &&
        !siteAccessPartiallyRestrictedDueToNoLogin(websiteConfiguration, auth);

    return (
        <AppBar
            position="relative"
            elevation={0}
            sx={{
                backgroundColor,
                color: textColor,
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => setOpenDrawer(!openDrawer)}
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Box
                    component="nav"
                    sx={{
                        flexShrink: { sm: 0 },
                    }}
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={openDrawer}
                        onClose={() => setOpenDrawer(false)}
                        ModalProps={{ keepMounted: true }}
                        PaperProps={{
                            sx: { width: '90%', maxWidth: 400 },
                        }}
                    >
                        <Toolbar>
                            <Box sx={{ flexGrow: 1 }} />
                            <PavoButton onClick={() => setOpenDrawer(false)} icon={<CloseIcon />} />
                        </Toolbar>
                        <div tabIndex={0} role="button">
                            {renderCategoryList()}
                            {renderMenuList()}
                            {shouldDisplayProducts && (
                                <>
                                    <ListItemButton
                                        onClick={() => {
                                            history.push(UrlEnum.PRODUCTS);
                                            setOpenDrawer(false);
                                        }}
                                    >
                                        <ListItemText
                                            primary={'Products'}
                                            primaryTypographyProps={{ fontWeight: 'bold' }}
                                        />
                                    </ListItemButton>
                                    {isLoggedIn(auth) && (
                                        <ListItemButton
                                            onClick={() => {
                                                history.push(UrlEnum.QUICK_ADD_PRODUCTS);
                                                setOpenDrawer(false);
                                            }}
                                        >
                                            <ListItemText
                                                primary={'Quick Add Products'}
                                                primaryTypographyProps={{ fontWeight: 'bold' }}
                                            />
                                        </ListItemButton>
                                    )}
                                </>
                            )}
                        </div>
                    </Drawer>
                </Box>
            </Toolbar>
        </AppBar>
    );
}

TopBarResponsive.propTypes = {
    menuList: PropTypes.array.isRequired,
};

export default TopBarResponsive;

import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { UrlEnum } from 'util/utils';

export default function PrivateRoute(props) {
    const auth = useSelector((state) => state.auth);
    if (auth) {
        return <Route {...props} />;
    } else {
        return (
            <Redirect
                to={{ pathname: UrlEnum.LOGIN, state: { login_redirect_url: location.href } }}
            />
        );
    }
}

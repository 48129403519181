import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import Header from './Header/Header';
import TopBar from './TopBar/TopBar';
import HeaderResponsive from './Header/HeaderResponsive';
import TopBarResponsive from './TopBar/TopBarResponsive';

import { isEmpty } from 'lodash';

import { fetchMenubars } from 'network/website';
import { useSelector, useDispatch } from 'react-redux';

import { fetchCartItemsCount, logout } from 'actions';
import {
    UrlEnum,
    isPointOfSaleWebsite,
    isPriceCheckerWebsite,
    siteAllowsOrdering,
} from 'util/utils';
import { useLocation } from 'react-router';

export default function HeaderBar(props) {
    const auth = useSelector((state) => state.auth);
    const cart = useSelector((state) => state.cart);
    const websiteConfiguration = useSelector((state) => state.websiteConfiguration);
    const serviceType = useSelector((state) => state.serviceType);
    const [menuList, setMenuList] = React.useState(null);
    const [displayTopBar, setDisplayTopBar] = React.useState(false);

    const dispatch = useDispatch();
    // useEffect doesn't change with window.location
    const location = useLocation();

    React.useEffect(() => {
        if (menuList == null) {
            fetchMenubars().then((allMenus) => {
                setMenuList(allMenus);
            });
        }
        return undefined;
    }, [menuList]);

    useEffect(() => {
        const shouldDisplay =
            !isPointOfSaleWebsite(serviceType) &&
            !isPriceCheckerWebsite(serviceType) &&
            location.pathname !== UrlEnum.CHECKOUT &&
            location.pathname !== UrlEnum.LOGIN &&
            siteAllowsOrdering(websiteConfiguration);

        if (shouldDisplay !== displayTopBar) {
            setDisplayTopBar(shouldDisplay);
        }
    }, [displayTopBar, location.pathname, serviceType, websiteConfiguration]);

    if (menuList == null) {
        return null;
    }

    const handleLogout = () => {
        dispatch(logout());
    };

    if (isEmpty(websiteConfiguration)) {
        return null;
    }

    let cartCount = 0;
    // if not logged in
    switch (auth) {
        case null:
            // unknown, same as logged out
            break;
        case false:
            break;
        default:
            if (isEmpty(cart) || cart.count == null) {
                dispatch(fetchCartItemsCount());
            } else {
                cartCount = cart.count;
            }
            break;
    }

    return (
        <>
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Header onLogout={() => handleLogout()} cartCount={cartCount} />
            </Box>
            {displayTopBar && (
                <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <TopBar menuList={menuList} />
                </Box>
            )}
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <HeaderResponsive onLogout={() => handleLogout()} cartCount={cartCount} />
            </Box>
            {displayTopBar && (
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <TopBarResponsive menuList={menuList} />
                </Box>
            )}
        </>
    );
}

import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Dialog, DialogTitle, IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function PavoDialog(props) {
    const { title, onClose, children } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog fullScreen={fullScreen} {...props}>
            {title && (
                <DialogTitle style={{ position: 'relative' }} sx={{ minWidth: '300px' }}>
                    {title}
                    {onClose ? (
                        <IconButton
                            style={{ position: 'absolute', right: '8px', top: '8px' }}
                            aria-label="close"
                            onClick={(e) => onClose()}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    ) : null}
                </DialogTitle>
            )}
            {children}
        </Dialog>
    );
}

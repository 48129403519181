import { FETCH_BACKDROP_STATUS } from '../constants/ActionTypes';

export default function backdropReducer(state = null, action) {
    switch (action.type) {
        case FETCH_BACKDROP_STATUS:
            return action.payload;
        default:
            return state;
    }
}

import _ from 'lodash';
import configuration from './configuration';
import axios from 'axios';

const createNewCustomer = async (params) => {
    try {
        // Required by OMS to be a string
        if (params.customer_type != null) params.customer_type = String(params.customer_type);
        const object = configuration.fetchWebsiteObject();
        const response = await axios.post('/api/customers', params, object);
        return response.data;
    } catch (error) {
        console.error(error);
        return;
    }
};

const updateCustomer = async (id, params) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.patch(`/api/customers/${id}`, params, object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchTerms = async (order_num) => {
    const object = configuration.fetchWebsiteObject();
    const response = await axios.get(`/api/terms`, object);
    return response.data;
};

const deletePaymentMethodForCustomer = async (customer_id, payment_id) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const res = await axios.delete(
            `/api/customer/${encodeURIComponent(customer_id)}/payment_method/${payment_id}`,
            object,
        );
        return res.data;
    } catch (error) {
        throw error.response.data;
    }
};

const addPaymentMethodForCustomer = async (customer_id, body) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const res = await axios.post(
            `/api/customer/${encodeURIComponent(customer_id)}/payment_method`,
            body,
            object,
        );
        return res.data;
    } catch (error) {
        throw error.response.data;
    }
};

const updatePaymentMethodForCustomer = async (customer_id, payment_id, body) => {
    try {
        const object = configuration.fetchWebsiteObject();
        const res = await axios.put(
            `/api/customer/${encodeURIComponent(customer_id)}/payment_method/${payment_id}`,
            body,
            object,
        );
        return res.data;
    } catch (error) {
        throw error.response.data;
    }
};

const fetchPaymentMethods = async (customer_id, params = {}) => {
    try {
        const object = configuration.fetchWebsiteObject(params);

        const res = await axios.get(
            `/api/customer/${encodeURIComponent(customer_id)}/payment_methods`,
            object,
        );
        return res.data;
    } catch (error) {
        console.error(error);
    }
    return {};
};

const registerUser = async (params, filesObject = {}) => {
    let registrationSuccess = null;
    const errorObject = {};

    try {
        const object = configuration.fetchWebsiteObject();

        if (!_.isEmpty(filesObject)) {
            // generate a link for every file we need to upload
            const propertyNames = Object.keys(filesObject);
            for (let i = 0; i < propertyNames.length; i++) {
                const propertyName = propertyNames[i];
                const result = await axios.get('/api/upload_file', object);
                await axios.put(result.data.url, filesObject[propertyName], {
                    headers: {
                        'Content-Type': filesObject[propertyName].type,
                    },
                });

                if (params.metadata == null) {
                    params.metadata = {};
                }
                params.metadata[propertyName] = {
                    type: 'document',
                    value: result.data.key,
                };
            }
        }
        const res = await axios.post('/api/register', params, object);
        registrationSuccess = res.data;
    } catch (error) {
        console.error('Error registration:', error);
        // if has error, display on page
        errorObject.errorString = 'This email already exists.';
    }

    if (!_.isEmpty(errorObject)) {
        throw errorObject;
    }

    if (_.isEmpty(registrationSuccess) || !registrationSuccess.register) {
        errorObject.errorString = registrationSuccess.message;

        throw errorObject;
    }

    return true;
};

const fetchCustomers = async (params) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/customers', object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchCustomerDetail = async (customerID) => {
    if (process.env.NODE_ENV === 'test') {
        return;
    }

    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get(`/api/customer/${customerID}`, object);
        return response.data;
    } catch (error) {
        console.error(error);
        return {};
    }
};

const fetchCustomerAnalytics = async (customerID, params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get(`/api/customer/analytics/${customerID}`, object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchCustomerSalesAnalytics = async (customerID, params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get(`/api/customer/sales_analytics/${customerID}`, object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchCustomerQuantityAnalytics = async (customerID, params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get(`/api/customer/quantity_analytics/${customerID}`, object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchTopCustomersAnalytics = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/customer/summary_analytics/top_customers', object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchCustomerItemAnalytics = async (customerID, params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get(`/api/customer/item_analytics/${customerID}`, object);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

const fetchSummaryData = async () => {
    const object = configuration.fetchWebsiteObject();
    const response = await axios.get('/api/customer/summary_data', object);
    return response.data;
};

export {
    createNewCustomer,
    updateCustomer,
    fetchTerms,
    deletePaymentMethodForCustomer,
    addPaymentMethodForCustomer,
    updatePaymentMethodForCustomer,
    fetchPaymentMethods,
    registerUser,
    fetchCustomers,
    fetchCustomerDetail,
    fetchCustomerAnalytics,
    fetchCustomerSalesAnalytics,
    fetchCustomerQuantityAnalytics,
    fetchTopCustomersAnalytics,
    fetchCustomerItemAnalytics,
    fetchSummaryData,
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty, merge } from 'lodash';
import { formattedWebsite, formattedPhoneNumber } from '@omsmastersystem/pavo-intl';

import { logout } from 'actions';
import {
    isB2BWebsite,
    isPriceCheckerWebsite,
    textColorForBackgroundColorHex,
    UrlEnum,
} from 'util/utils';
import { fetchWebsiteSocialMedia } from 'network/content';
import LogoImage from '../images/logo';
import {
    Box,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    SvgIcon,
    Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import EmailIcon from '@mui/icons-material/Email';
import FaxIcon from '@mui/icons-material/Fax';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { useHistory } from 'react-router';
import AccountMenuList from 'components/AccountMenuList';

// until MUI catches up to Gen Z users
const TikTokIcon = () => (
    <SvgIcon viewBox={'0 0 50 50'}>
        <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </SvgIcon>
);

export default function Footer() {
    const [socialMediaLinks, setSocialMediaLinks] = React.useState(undefined);
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const serviceType = useSelector((store) => store.serviceType);
    const auth = useSelector((store) => store.auth);
    const dispatch = useDispatch();
    const history = useHistory();

    React.useEffect(() => {
        if (socialMediaLinks === undefined) {
            fetchWebsiteSocialMedia().then((links) => {
                setSocialMediaLinks(links);
            });
        }
        return undefined;
    }, [socialMediaLinks]);

    if (isEmpty(websiteConfiguration)) {
        return null;
    }

    const handleLogout = () => {
        dispatch(logout());
    };

    const renderSocialMedia = () => {
        if (socialMediaLinks == null) {
            return null;
        }
        return (
            <Grid container alignItems={'center'} justifyContent={'center'}>
                {socialMediaLinks.map((e, index) => {
                    let icon = null;
                    switch (e.type) {
                        case 'facebook':
                            icon = <FacebookRoundedIcon />;
                            break;
                        case 'twitter':
                            icon = <TwitterIcon />;
                            break;
                        case 'linkedin':
                            icon = <LinkedInIcon />;
                            break;
                        case 'instagram':
                            icon = <InstagramIcon />;
                            break;
                        case 'youtube':
                            icon = <YouTubeIcon />;
                            break;
                        case 'tiktok':
                            icon = <TikTokIcon />;
                            break;
                        default:
                            break;
                    }

                    return (
                        <Grid item key={index}>
                            <IconButton type="a" href={formattedWebsite(e.url)}>
                                {icon}
                            </IconButton>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const renderAccountProfile = () => {
        return (
            <List dense>
                <ListItem>
                    <ListItemText
                        primary="My Account"
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                </ListItem>
                <AccountMenuList
                    renderLink={(url, text, index) => {
                        return (
                            <ListItemButton key={url} dense onClick={() => history.push(url)}>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        );
                    }}
                />
                <ListItemButton dense onClick={() => handleLogout()}>
                    <ListItemText primary="Log out" />
                </ListItemButton>
            </List>
        );
    };

    const renderWebsitePages = () => {
        const { footer_content } = websiteConfiguration;
        const state = {};
        const customWebpages = [];
        footer_content.forEach((e) => {
            switch (e.type) {
                case 'about_us':
                    state.showAboutUsLink = e.display_content;
                    break;
                case 'contact_us':
                    state.showContactUsLink = e.display_content;
                    break;
                case 'privacy_policy':
                    state.showPrivacyPolicyLink = e.display_content;
                    break;
                case 'terms_conditions':
                    state.showTermsConditionsLink = e.display_content;
                    break;
                default:
                    if (e.custom && !isEmpty(e.slug) && e.display_content) {
                        customWebpages.push(e);
                    }
                    break;
            }
        });
        const {
            showContactUsLink,
            showAboutUsLink,
            showTermsConditionsLink,
            showPrivacyPolicyLink,
        } = state;

        const hideAboutColumn =
            !showContactUsLink &&
            !showAboutUsLink &&
            !showTermsConditionsLink &&
            !showPrivacyPolicyLink &&
            !websiteConfiguration.show_faq_page &&
            isEmpty(customWebpages);

        if (hideAboutColumn) {
            return null;
        }

        return (
            <List dense>
                <ListItem>
                    <ListItemText primary="Pages" primaryTypographyProps={{ fontWeight: 'bold' }} />
                </ListItem>
                {showAboutUsLink && (
                    <ListItemButton dense onClick={() => history.push(UrlEnum.ABOUT_US)}>
                        <ListItemText primary={'About Us'} />
                    </ListItemButton>
                )}
                {showContactUsLink && (
                    <ListItemButton dense onClick={() => history.push(UrlEnum.CONTACT)}>
                        <ListItemText primary={'Contact Us'} />
                    </ListItemButton>
                )}
                {websiteConfiguration.show_faq_page && (
                    <ListItemButton dense onClick={() => history.push(UrlEnum.FAQ)}>
                        <ListItemText primary={'FAQs'} />
                    </ListItemButton>
                )}
                {showTermsConditionsLink && (
                    <ListItemButton dense onClick={() => history.push(UrlEnum.TERMS_CONDITIONS)}>
                        <ListItemText primary={'Terms & Conditions'} />
                    </ListItemButton>
                )}
                {showPrivacyPolicyLink && (
                    <ListItemButton dense onClick={() => history.push(UrlEnum.PRIVACY_POLICY)}>
                        <ListItemText primary={'Privacy Policy'} />
                    </ListItemButton>
                )}
                {customWebpages.map((e) => (
                    <ListItemButton dense key={e.slug} onClick={() => history.push(`/${e.slug}`)}>
                        <ListItemText primary={e.type} />
                    </ListItemButton>
                ))}
            </List>
        );
    };

    const renderAddressString = () => {
        const { primary_address } = websiteConfiguration;
        let addressString = '';
        if (primary_address.address_line_1) {
            addressString += primary_address.address_line_1;
        }

        // need to show address 2
        if (primary_address.address_line_2) {
            addressString += ` ${primary_address.address_line_2}, `;
        } else if (!isEmpty(addressString)) {
            addressString += ', ';
        }

        if (primary_address.city || primary_address.state) {
            if (primary_address.city && primary_address.state) {
                addressString += primary_address.city + ', ' + primary_address.state + ' ';
            } else if (primary_address.city && !primary_address.state) {
                addressString += primary_address.city + ' ';
            } else if (primary_address.state && !primary_address.city) {
                addressString += primary_address.state + ' ';
            }
        }

        if (primary_address.postal_code) {
            addressString += primary_address.postal_code;
        }

        return addressString;
    };

    const renderStoreInformation = () => {
        const { primary_address } = websiteConfiguration;
        const addressString = renderAddressString();
        const phoneString = formattedPhoneNumber(primary_address.phone);
        const faxString = formattedPhoneNumber(primary_address.fax);

        const showAddressString = !isEmpty(addressString);
        const renderPhoneString = !isEmpty(phoneString);
        const renderFaxString = !isEmpty(faxString);
        const renderEmailString = !isEmpty(primary_address.email);

        const renderColumn =
            showAddressString || renderPhoneString || renderFaxString || renderEmailString;

        if (!renderColumn) {
            return null;
        }

        return (
            <Grid container sx={{ justifyContent: 'center' }}>
                {showAddressString && (
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography>
                            <IconButton>
                                <BusinessIcon />
                            </IconButton>
                            {addressString}
                        </Typography>
                    </Grid>
                )}
                {renderPhoneString && (
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography>
                            <IconButton
                                onClick={() => window.open(`tel://${primary_address.phone}`)}
                            >
                                <PhoneIcon />
                            </IconButton>
                            {phoneString}
                        </Typography>
                    </Grid>
                )}
                {renderEmailString && (
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography>
                            <IconButton
                                onClick={() => window.open(`mailto://${primary_address.email}`)}
                            >
                                <EmailIcon />
                            </IconButton>
                            {primary_address.email}
                        </Typography>
                    </Grid>
                )}
                {renderFaxString && (
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography>
                            <IconButton
                                onClick={() => window.open(`mailto://${primary_address.email}`)}
                            >
                                <FaxIcon />
                            </IconButton>
                            {faxString}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        );
    };

    const displayPages =
        !isPriceCheckerWebsite(serviceType) && window.location.pathname !== UrlEnum.CHECKOUT;

    return (
        <ThemeProvider
            theme={(outerTheme) => {
                const textColor =
                    websiteConfiguration.footer_font_color ??
                    textColorForBackgroundColorHex(websiteConfiguration.footer_color);

                return createTheme(
                    merge(cloneDeep(outerTheme), {
                        components: {
                            MuiTypography: {
                                styleOverrides: {
                                    root: {
                                        color: textColor,
                                    },
                                },
                            },
                            MuiIconButton: {
                                styleOverrides: {
                                    root: {
                                        color: textColor,
                                    },
                                },
                            },
                        },
                    }),
                );
            }}
        >
            <Box sx={{ backgroundColor: websiteConfiguration.footer_color }}>
                <Grid
                    container
                    direction="row"
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                    textAlign="center"
                    sx={{
                        paddingLeft: { xs: '20px', md: '40px' },
                        paddingRight: { xs: '20px', md: '40px' },
                        pt: { xs: '20px', md: '40px' },
                        pb: '10px',
                        maxWidth: '800px',
                        ml: 'auto',
                        mr: 'auto',
                    }}
                >
                    {displayPages && (
                        <>
                            {auth ? (
                                <>
                                    <Grid item xs={12} sm={6}>
                                        {renderAccountProfile()}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {renderWebsitePages()}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <Grid item xs={12}>
                                        {renderWebsitePages()}
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                </Grid>
                <Grid
                    container
                    direction="row"
                    alignItems={'flex-start'}
                    justifyContent={'center'}
                    textAlign="center"
                    sx={{
                        paddingLeft: { xs: '20px', md: '40px' },
                        paddingRight: { xs: '20px', md: '40px' },
                        pt: '10px',
                        pb: '10px',
                        maxWidth: '1600px',
                        ml: 'auto',
                        mr: 'auto',
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <LogoImage />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={{ mb: 5 }}>
                        {renderStoreInformation()}
                    </Grid>
                    <Grid item xs={12} lg={12}>
                        <Box sx={{ display: 'block', mr: 'auto', ml: 'auto' }}>
                            <Typography gutterBottom>
                                {`© ${new Date().getFullYear()} ${
                                    websiteConfiguration.company_name
                                }. Built with `}
                                <Link href="https://www.omspavo.com/">{'Pavo'}</Link>
                                {'.'}
                            </Typography>
                        </Box>
                        {websiteConfiguration.comp_id.startsWith('OMS') && (
                            <Typography gutterBottom>
                                {`All the items, pictures, and collection images used in this site are only for software development testing, not for resale.`}
                            </Typography>
                        )}
                        {isB2BWebsite(serviceType) && renderSocialMedia()}
                    </Grid>
                </Grid>
            </Box>
        </ThemeProvider>
    );
}

import { FETCH_SHIPPING_METHODS } from '../constants/ActionTypes';

const initState = {
    shippingMethod: null,
};

const orderReducer = (state = initState, action) => {
    switch (action.type) {
        case FETCH_SHIPPING_METHODS:
            return {
                ...state,
                shippingMethod: action.payload,
            };
        default:
            return state;
    }
};

export default orderReducer;

import { combineReducers } from 'redux';

// Import custom components
import authReducer from './auth';
import orderReducer from './orders';
import productReducer from './products';
import cartReducer from './cart';
import filtersReducer from './filters';
import compareReducer from './compare';
import categoriesReducer from './categories';
import customersReducer from './customers';
import websiteReducer from './website';
import serviceTypeReducer from './service_type';
import snackbarReducer from './snackbar';
import wsReducer from './ws';
import backdropReducer from './backdrop';

const rootReducer = combineReducers({
    auth: authReducer,
    category: categoriesReducer,
    data: productReducer,
    orders: orderReducer,
    cart: cartReducer,
    pavoGlobalFilter: filtersReducer,
    compare: compareReducer,
    selectedCustomer: customersReducer,
    websiteConfiguration: websiteReducer,
    serviceType: serviceTypeReducer,
    snackbar: snackbarReducer,
    backdrop: backdropReducer,
    ws: wsReducer,
});

export default rootReducer;

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCart, fetchUser, setSnackbar } from 'actions';
import { fetchCustomers } from 'network/customer';
import { saveWebCustomer } from 'network/user';
import PavoDialog from 'components/Dialog/PavoDialog';
import {
    Box,
    DialogContent,
    TextField,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    IconButton,
    DialogActions,
    Button,
    Typography,
    CircularProgress,
} from '@mui/material';
import PavoHeaderCell from '../table/PavoHeaderCell';
import PavoTableRow from '../table/PavoTableRow';
import { Check, Close, Remove, Store } from '@mui/icons-material';
import { debounce, isEmpty } from 'lodash';
import { getAddressComponent } from 'util/chainstore';

function QuickSelectCustomer({ open, onClose }) {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth);

    const [keyword, setKeyword] = useState('');
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);

    const debouncedCustomerSearch = useMemo(
        () =>
            debounce(async (keyword) => {
                if (!keyword) {
                    setCustomers([]);
                    return;
                }

                setLoading(true);

                try {
                    const result = await fetchCustomers({
                        keyword,
                        include_chain_stores: true,
                    });
                    setCustomers(result?.customers ?? []);
                } catch (error) {
                    dispatch(
                        setSnackbar({
                            severity: 'error',
                            message: 'An error was encountered fetching the customers',
                        }),
                    );
                } finally {
                    setLoading(false);
                }
            }, 500),
        [dispatch],
    );

    const searchCustomer = (keyword) => {
        setKeyword(keyword);

        if (keyword) {
            debouncedCustomerSearch(keyword);
        } else {
            // Prevent 500 ms delay with the debounce, but also cancel the network fetch
            setCustomers([]);
            debouncedCustomerSearch(keyword);
        }
    };

    const handleClose = () => {
        setKeyword('');
        setCustomers([]);
        onClose();
    };

    const selectCustomer = async (customerID, storeNum) => {
        setLoading(true);

        try {
            const data = {
                customer_id: customerID,
            };

            if (storeNum) {
                data.store_num = storeNum;
            } else {
                data.store_num = '';
            }

            await saveWebCustomer(data);

            await dispatch(fetchUser());
            dispatch(fetchCart());

            dispatch(
                setSnackbar({
                    severity: 'success',
                    message: `The customer ${customerID} was successfully selected.`,
                }),
            );

            handleClose();
        } catch (error) {
            dispatch(
                setSnackbar({
                    severity: 'error',
                    message: 'An error was encountered selecting the customer. Please try again.',
                }),
            );
        } finally {
            setLoading(false);
        }
    };

    const renderChainStoreRows = (customer) => {
        const { chain_stores, customer_id } = customer;
        if (isEmpty(chain_stores)) {
            return null;
        }

        const chainStores = [];

        for (const chainStore of chain_stores) {
            const { customer_name, store_address, store_num, id } = chainStore;

            const sameCustomerSelected =
                auth.customer_id === customer_id && auth.chain_store_id === id;

            const addressRows = getAddressComponent(store_address);

            chainStores.push(
                <PavoTableRow key={`${customer_id}-${store_num}-quick-select-row`}>
                    <TableCell>
                        <IconButton
                            color="success"
                            onClick={() => {
                                if (!sameCustomerSelected) {
                                    selectCustomer(customer_id, store_num);
                                }
                            }}
                            data-testid={`quick-select-store-${customer_id}-${store_num}`}
                        >
                            {sameCustomerSelected ? <Remove data-testid="selected" /> : <Check />}
                        </IconButton>
                    </TableCell>
                    <TableCell>{store_num}</TableCell>
                    <TableCell>
                        {[
                            <Box
                                key={`${customer_id}-${store_num}-quick-select-row-customer-name`}
                                sx={{ display: 'flex', alignItems: 'center' }}
                            >
                                <Typography>{customer_name}</Typography>
                                <Store fontSize={'12'} />
                            </Box>,
                            ...addressRows,
                        ]}
                    </TableCell>
                </PavoTableRow>,
            );
        }

        return chainStores;
    };

    const renderCustomersTable = () => {
        return (
            <>
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <PavoHeaderCell>{''}</PavoHeaderCell>
                                <PavoHeaderCell>{'ID'}</PavoHeaderCell>
                                <PavoHeaderCell>{'Name'}</PavoHeaderCell>
                            </TableRow>
                        </TableHead>
                        {!loading && (
                            <TableBody>
                                {customers.map((customer) => {
                                    const { chain_stores } = customer;

                                    const customerID = customer?.customer_id ?? '';
                                    let customerName = customer?.customer_name ?? '';

                                    const sameCustomerSelected =
                                        auth?.customer_id &&
                                        auth.customer_id === customerID &&
                                        isEmpty(auth.chain_store_id);

                                    return (
                                        <>
                                            <PavoTableRow key={customerID}>
                                                <TableCell>
                                                    {sameCustomerSelected ? (
                                                        <IconButton size="small">
                                                            <Remove size="small" />
                                                        </IconButton>
                                                    ) : (
                                                        <IconButton
                                                            data-testid={`quick-select-customer-${customerID}`}
                                                            size="small"
                                                            onClick={() => {
                                                                selectCustomer(customerID);
                                                            }}
                                                            color="success"
                                                        >
                                                            <Check size="small" />
                                                        </IconButton>
                                                    )}
                                                </TableCell>
                                                <TableCell>{customerID}</TableCell>
                                                <TableCell>{customerName}</TableCell>
                                            </PavoTableRow>
                                            {!isEmpty(chain_stores) &&
                                                renderChainStoreRows(customer)}
                                        </>
                                    );
                                })}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                {loading && (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 2 }}>
                        <CircularProgress fontSize={'12'} />
                    </Box>
                )}
            </>
        );
    };

    return (
        <PavoDialog open={open} onClose={() => onClose()}>
            <DialogContent>
                <Box
                    sx={{
                        pt: 2,
                        minHeight: '200px',
                        minWidth: { sm: '500px' },
                        position: 'relative',
                    }}
                >
                    <Box sx={{ mb: 2, position: 'sticky', top: 0, zIndex: 9999 }}>
                        <TextField
                            autoFocus
                            label="Customer Search"
                            size="small"
                            fullWidth
                            value={keyword}
                            onChange={(event) => {
                                const keyword = event.target.value;
                                searchCustomer(keyword);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <>
                                        {keyword && (
                                            <IconButton onClick={() => searchCustomer('')}>
                                                <Close />
                                            </IconButton>
                                        )}
                                    </>
                                ),
                            }}
                        />
                    </Box>
                    <Box sx={{ maxHeight: { xs: '80vh', md: '60vh' }, overflow: 'auto' }}>
                        {renderCustomersTable()}
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        handleClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </PavoDialog>
    );
}

export default QuickSelectCustomer;

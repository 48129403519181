import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { changePassword } from 'network/user';
import { useDispatch } from 'react-redux';
import { setSnackbar } from 'actions';

const MinimumPasswordLength = 8;

function ChangePasswordDialog(props) {
    const [password, setPassword] = React.useState('');
    const [confirmPassword, setConfirmPassword] = React.useState('');
    const [loadingSave, setLoadingSave] = React.useState(false);

    const dispatch = useDispatch();

    let passwordHelperText = null;
    let confirmPasswordHelperText = null;
    if (password.length < MinimumPasswordLength && password.length > 0) {
        passwordHelperText = `Minimum of ${MinimumPasswordLength} characters required.`;
    }

    if (password !== confirmPassword && password.length > 0) {
        passwordHelperText = `Passwords do not match.`;
        confirmPasswordHelperText = `Passwords do not match.`;
    }

    let disabled = !(
        password.length >= MinimumPasswordLength &&
        confirmPassword.length >= MinimumPasswordLength &&
        password === confirmPassword
    );

    const handleClose = () => {
        setPassword('');
        setConfirmPassword('');
        setLoadingSave(false);
        props.onClose();
    };

    const handleSavePassword = async () => {
        setLoadingSave(true);

        await changePassword({
            password,
        });

        handleClose();
        dispatch(
            setSnackbar({
                message: 'Your password was changed successfully.',
                severity: 'success',
            }),
        );
    };

    return (
        <Dialog open={props.open}>
            <DialogTitle>{'Change Password'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Enter a new password to use for logging into the website. Passwords must be at least ${MinimumPasswordLength} characters.`}
                </DialogContentText>
                <TextField
                    fullWidth
                    autoFocus
                    required
                    error={passwordHelperText != null}
                    helperText={passwordHelperText}
                    value={password}
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    label={'Password'}
                    variant="standard"
                    margin="dense"
                />
                <TextField
                    fullWidth
                    required
                    type="password"
                    error={confirmPasswordHelperText}
                    helperText={confirmPasswordHelperText}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    label={'Confirm Password'}
                    variant="standard"
                    margin="dense"
                />
            </DialogContent>
            <DialogActions>
                <Button disabled={loadingSave} onClick={() => handleClose()}>
                    {'Cancel'}
                </Button>
                <LoadingButton
                    loading={loadingSave}
                    disabled={disabled}
                    onClick={() => handleSavePassword()}
                >
                    {'Save'}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

ChangePasswordDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ChangePasswordDialog;

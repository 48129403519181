const { UrlEnum } = require('./utils');

// This for b2b/sales, used by Header and HeaderRespnosive for the SearchTextField
const handleSearchKeyword = (ReactRouterHistory, keyword) => {
    if (!ReactRouterHistory || !keyword) return;
    const pathname = ReactRouterHistory?.location?.pathname ?? '';
    const url =
        pathname === UrlEnum.QUICK_ADD_PRODUCTS ? UrlEnum.QUICK_ADD_PRODUCTS : UrlEnum.PRODUCTS;
    ReactRouterHistory.push(`${url}?keyword=${keyword}`);
};

// This for b2b/sales, used by Header and HeaderRespnosive for the SearchTextField
const handleSearchProduct = (ReactRouterHistory, itemNum) => {
    if (!ReactRouterHistory || !itemNum) return;

    ReactRouterHistory.push(`${UrlEnum.PRODUCT_NID}/${encodeURIComponent(itemNum)}`);
};

export { handleSearchKeyword, handleSearchProduct };

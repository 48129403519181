import { FETCH_SELECTED_CUSTOMER } from '../constants/ActionTypes';

export default function customersReducer(state = {}, action) {
    switch (action.type) {
        case FETCH_SELECTED_CUSTOMER:
            return action.payload;
        default:
            return state;
    }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logo from 'components/images/logo';
import SearchTextField from './SearchTextField';
import SearchIcon from '@mui/icons-material/Search';
import ScienceIcon from '@mui/icons-material/Science';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import {
    isPriceCheckerWebsite,
    UrlEnum,
    textColorForBackgroundColorHex,
    isProduction,
    isSalesRepWebsite,
    siteAllowsOrdering,
} from 'util/utils';

import {
    Button,
    Box,
    Menu,
    MenuItem,
    AppBar,
    Toolbar,
    IconButton,
    Badge,
    DialogContent,
    Tooltip,
    Fab,
    Avatar,
} from '@mui/material';
import PavoDialog from 'components/Dialog/PavoDialog';
import SelectCustomerID from 'components/Button/SelectCustomerID';
import { handleSearchKeyword, handleSearchProduct } from 'util/search';
import AccountMenuList from 'components/AccountMenuList';
import { isEmpty } from 'lodash';

function HeaderResponsive(props) {
    const auth = useSelector((state) => state.auth);
    const websiteConfiguration = useSelector((state) => state.websiteConfiguration);
    const serviceType = useSelector((state) => state.serviceType);

    const [anchorElAccountSettings, setAnchorElAccountSettings] = useState(null);
    const [searchDialogOpen, setSearchDialogOpen] = useState(false);

    const { cartCount } = props;

    const history = useHistory();

    const renderAccountMenu = () => {
        const username = isSalesRepWebsite(serviceType) ? auth.oms_login : auth.username;

        return (
            <Menu
                open={Boolean(anchorElAccountSettings)}
                anchorEl={anchorElAccountSettings}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={() => {
                    setAnchorElAccountSettings(null);
                }}
            >
                <MenuItem sx={{ color: 'black', ml: 1, borderBottom: '2px solid lightgray' }}>
                    {username}
                </MenuItem>
                <AccountMenuList
                    renderLink={(url, text, index) => {
                        return (
                            <NavLink
                                key={`menuLink-${index}`}
                                activeClassName="active-menuLink"
                                onClick={() => setAnchorElAccountSettings(null)}
                                to={url}
                            >
                                <MenuItem sx={{ ml: 1, color: 'black' }}>{text}</MenuItem>
                            </NavLink>
                        );
                    }}
                />
                <NavLink to="#" onClick={(e) => props.onLogout(e)}>
                    <MenuItem sx={{ color: 'black', ml: 1 }}>Log out</MenuItem>
                </NavLink>
            </Menu>
        );
    };

    const renderAccountButton = () => {
        let icon = null;
        if (!auth) {
            icon = <AccountCircleIcon />;
        } else {
            const { customer_name } = auth;
            if (isEmpty(customer_name)) {
                icon = <AccountCircleIcon />;
            } else {
                const splitName = customer_name.split(' ');
                let initials = '';
                if (splitName.length > 1) {
                    initials = splitName[0].charAt(0) + splitName[1].charAt(0);
                } else {
                    initials = splitName[0].charAt(0);
                }
                icon = <Avatar>{initials}</Avatar>;
            }
        }
        return (
            <IconButton
                id="account-button"
                onClick={(event) => {
                    if (auth) {
                        setAnchorElAccountSettings(event.target);
                    } else {
                        history.push(UrlEnum.LOGIN, { login_redirect_url: location.href });
                    }
                }}
            >
                {icon}
            </IconButton>
        );
    };

    const renderCart = () => {
        if (!auth || !siteAllowsOrdering(websiteConfiguration)) {
            return null;
        }

        return (
            <IconButton
                id="shopping-cart-button"
                onClick={(event) => {
                    history.push('/cart');
                }}
            >
                <Badge max={99} badgeContent={cartCount} color="error">
                    <ShoppingCartIcon />
                </Badge>
            </IconButton>
        );
    };

    const priceCheckerSite = isPriceCheckerWebsite(serviceType);
    const checkoutPage = window.location.pathname === UrlEnum.CHECKOUT;
    const loginPage = window.location.pathname === UrlEnum.LOGIN;
    const displaySearchBar = !priceCheckerSite && !checkoutPage && !loginPage;

    return (
        <>
            <AppBar
                position={priceCheckerSite ? 'fixed' : 'relative'}
                elevation={0}
                sx={{
                    backgroundColor: websiteConfiguration.header_color,
                    color: textColorForBackgroundColorHex(websiteConfiguration.header_color),
                    minHeight: 60,
                    paddingTop: priceCheckerSite ? '5px' : '6px',
                    paddingBottom: priceCheckerSite ? '0px' : '4px',
                    borderBottom:
                        checkoutPage || loginPage
                            ? `2px solid ${websiteConfiguration.topbar_color}`
                            : 'none',
                }}
            >
                {displaySearchBar ? (
                    <Toolbar disableGutters={true} sx={{ pl: 1, pr: 1 }}>
                        <Logo />
                        {!isProduction() && (
                            <Tooltip title="DEVELOPMENT">
                                <Fab color="warning" size="small" disableRipple={true}>
                                    <ScienceIcon fontSize="small" />
                                </Fab>
                            </Tooltip>
                        )}
                        <Box sx={{ flexGrow: 1 }} />
                        {siteAllowsOrdering(websiteConfiguration) && (
                            <IconButton
                                onClick={(event) => {
                                    // Prevents focus on this button when the dialog is closed later
                                    event.currentTarget.blur();
                                    setSearchDialogOpen(true);
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        )}
                        {renderAccountButton()}
                        {renderAccountMenu()}
                        {renderCart()}
                    </Toolbar>
                ) : (
                    <Toolbar
                        sx={{
                            justifyContent: 'center',
                            ml: 'auto',
                            mr: 'auto',
                        }}
                    >
                        <Logo />
                    </Toolbar>
                )}
                <SelectCustomerID />
            </AppBar>
            <PavoDialog
                open={searchDialogOpen}
                onClose={() => {
                    setSearchDialogOpen(false);
                }}
                sx={{
                    display: {
                        xs: 'block',
                        md: 'none',
                    },
                }}
            >
                <DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                        <Button
                            onClick={() => {
                                setSearchDialogOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <SearchTextField
                            width={'90vw'}
                            size="small"
                            onSearchKeyword={(keyword) => {
                                setSearchDialogOpen(false);
                                handleSearchKeyword(history, keyword);
                            }}
                            onSelectProduct={(itemNum) => {
                                setSearchDialogOpen(false);
                                handleSearchProduct(history, itemNum);
                            }}
                        />
                    </Box>
                </DialogContent>
            </PavoDialog>
        </>
    );
}

HeaderResponsive.propTypes = {
    onLogout: PropTypes.func.isRequired,
    cartCount: PropTypes.number,
};

export default HeaderResponsive;

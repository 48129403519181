import { createCipheriv } from 'crypto-browserify';
import { ServiceTypeEnum } from 'util/utils';

class ConfigClass {
    fetchWebsiteObject(params) {
        const opt = {
            apiKey: process.env.REACT_APP_API_KEY,
            enabledService: process.env.REACT_APP_ENABLED_SERVICE,
            authentication_salt: process.env.REACT_APP_HASH_SALT.replace(/-/g, ''),
        };

        const salt = process.env.NODE_ENV === 'test' ? 'testing' : opt.authentication_salt;

        const options = {
            responseType: 'json',
            headers: {
                Accept: 'application/json',
            },
        };

        if (opt.apiKey) {
            options.headers.authorization = `Basic ${opt.apiKey}`;
        }
        const currentTime = new Date().toISOString();
        const { host } = window.location;
        const iv = '0000000000000000'; // initialization vector

        const cipher = createCipheriv('aes256', salt, iv);
        let hash = cipher.update(`${currentTime},${host}`, 'utf8', 'hex') + cipher.final('hex');

        options.headers['x-pavob2b-hash'] = hash;
        options.headers['x-pavob2b-host'] = host;
        options.headers['x-pavob2b-timestamp'] = currentTime;

        switch (opt.enabledService) {
            case ServiceTypeEnum.SalesRep:
                options.headers['x-pavob2b-website-type'] = 'sales';
                break;
            case ServiceTypeEnum.PriceChecker:
                options.headers['x-pavob2b-website-type'] = 'price_checker';
                break;
            case ServiceTypeEnum.PointOfSale:
                options.headers['x-pavob2b-website-type'] = 'point_of_sale';
                break;
            case ServiceTypeEnum.B2B:
            default:
                options.headers['x-pavob2b-website-type'] = 'b2b';
                break;
        }

        if (params != null) {
            options.params = params;
        }
        return options;
    }
}

const configObject = new ConfigClass();
export default configObject;

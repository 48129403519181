import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Typography } from '@mui/material';

function PavoLoadingSpinner(props) {
    const { loadingText = 'Loading…', subtitleText } = props;

    return (
        <>
            <Box display="block" justifyContent="center" mb="10px" mt="15px">
                <Typography variant="h5" align="center" color="textPrimary">
                    {loadingText}
                </Typography>
                {subtitleText && (
                    <Typography variant="subtitle1" align="center">
                        {subtitleText}
                    </Typography>
                )}
            </Box>
            <Box display="flex" justifyContent="center" mb="10px">
                <CircularProgress />
            </Box>
        </>
    );
}

PavoLoadingSpinner.propTypes = {
    loadingText: PropTypes.string,
    subtitleText: PropTypes.string,
};

export default PavoLoadingSpinner;

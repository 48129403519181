import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { siteAccessPartiallyRestrictedDueToNoLogin, UrlEnum } from 'util/utils';

export default function RestrictProductDisplaySiteAccessRoute(props) {
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const auth = useSelector((store) => store.auth);

    if (siteAccessPartiallyRestrictedDueToNoLogin(websiteConfiguration, auth)) {
        return (
            <Redirect
                to={{ pathname: UrlEnum.LOGIN, state: { login_redirect_url: location.href } }}
            />
        );
    } else {
        return <Route {...props} />;
    }
}

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { ListItem, ListItemText, Collapse, IconButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { UrlEnum } from 'util/utils';

function FilterCategoryRow({ categoryObject }) {
    const { category, children, level, open, selected } = categoryObject;
    const history = useHistory();

    const [showChildren, setShowChildren] = useState(open === true);

    useEffect(() => {
        if (open) setShowChildren(true);
    }, [open]);

    const handleCategoryClick = (cat) => {
        if (!_.isEmpty(cat)) {
            const pathname = history.location.pathname;

            history.push(UrlEnum.PRODUCT_CATEGORY_GETTER(pathname, encodeURIComponent(cat.slug)));
            setShowChildren(!showChildren);
        }
    };
    return (
        <>
            <ListItem
                sx={{ cursor: 'pointer' }}
                secondaryAction={
                    !_.isEmpty(children) && (
                        <IconButton
                            onClick={() => {
                                setShowChildren(!showChildren);
                            }}
                            edge="end"
                            aria-label="show category children"
                        >
                            {showChildren ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    )
                }
            >
                <ListItemText
                    primaryTypographyProps={{ fontWeight: selected ? 'bold' : 'regular' }}
                    primary={category.title}
                    sx={{ pl: 2 * level }}
                    onClick={() => handleCategoryClick(category)}
                />
            </ListItem>
            {!_.isEmpty(children) && (
                <Collapse in={showChildren} timeout="auto" unmountOnExit>
                    {children.map((childCategoryObject) => {
                        const { category_id } = childCategoryObject;
                        return (
                            <FilterCategoryRow
                                categoryObject={childCategoryObject}
                                key={`category-row-${category_id}`}
                            />
                        );
                    })}
                </Collapse>
            )}
        </>
    );
}

export default FilterCategoryRow;

import { FETCH_LOGIN } from '../constants/ActionTypes';

export default function authReducer(state = null, action) {
    switch (action.type) {
        case FETCH_LOGIN:
            return action.payload || false;
        default:
            return state;
    }
}

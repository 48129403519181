import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

const PavoButton = React.forwardRef(
    (
        {
            variant,
            buttonText,
            iconPosition = 'start',
            icon,
            onClick,
            asyncOnClick,
            loading: loadingProps,
            ...rest
        },
        ref,
    ) => {
        const [loading, setLoading] = useState(loadingProps === true);

        const combinedLoading = loading || loadingProps;

        return (
            <>
                {buttonText ? (
                    <LoadingButton
                        variant={variant}
                        {...rest}
                        ref={ref}
                        loading={combinedLoading}
                        startIcon={iconPosition === 'start' && icon ? icon : null}
                        endIcon={iconPosition === 'end' && icon ? icon : null}
                        onClick={async (event) => {
                            if (asyncOnClick) {
                                setLoading(true);
                                await asyncOnClick(event.target);
                                setLoading(false);
                            } else if (onClick) {
                                onClick();
                            }
                        }}
                    >
                        {buttonText}
                    </LoadingButton>
                ) : (
                    <IconButton
                        variant={variant}
                        {...rest}
                        ref={ref}
                        onClick={async (event) => {
                            if (asyncOnClick) {
                                setLoading(true);
                                await asyncOnClick(event.target, event);
                                setLoading(false);
                            } else if (onClick) {
                                onClick(event);
                            }
                        }}
                    >
                        {combinedLoading ? <CircularProgress size={20} /> : icon}
                    </IconButton>
                )}
            </>
        );
    },
);

PavoButton.displayName = 'PavoButton';

PavoButton.propTypes = {
    variant: PropTypes.string,
    buttonText: PropTypes.string,
    iconPosition: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.any, PropTypes.elementType]),
    onClick: PropTypes.func,
    asyncOnClick: PropTypes.func,
};

export default PavoButton;

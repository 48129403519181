import React from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
// middlewares
import thunkMiddleware from 'redux-thunk';

// Import custom components
import rootReducer from 'reducers/index';

export default function Root({ children, initialState = {} }) {
    /**
     * Create a Redux store that holds the app state.
     */
    const store = createStore(
        rootReducer,
        initialState,
        compose(
            applyMiddleware(thunkMiddleware),

            //For working redux dev tools in chrome (https://github.com/zalmoxisus/redux-devtools-extension)
            window.__REDUX_DEVTOOLS_EXTENSION__
                ? window.__REDUX_DEVTOOLS_EXTENSION__()
                : function (f) {
                      return f;
                  },
        ),
    );

    return <Provider store={store}>{children}</Provider>;
}

// #region login
export const FETCH_LOGIN = 'FETCH_LOGIN';
// #endregion

// #region snackbar notifications
export const FETCH_SNACKBAR_STATUS = 'FETCH_SNACKBAR_STATUS';
export const FETCH_BACKDROP_STATUS = 'FETCH_BACKDROP_STATUS';

// #region Website type
export const FETCH_ENABLED_SERVICE = 'FETCH_ENABLED_SERVICE';
// #endregion

// #region admin
export const FETCH_WEBSITE_CONFIG = 'FETCH_WEBSITE_CONFIG';
// #endregion

// #region categories
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
// #endregion

// #region customer details
export const FETCH_SELECTED_CUSTOMER = 'FETCH_SELECTED_CUSTOMER';
// #endregion

// #region Products
export const FETCH_PREPACK_RUN = 'FETCH_PREPACK_RUN';
export const FETCH_DEFINITIONS = 'FETCH_DEFINITIONS';
export const FETCH_PRODUCT_DETAIL_COLOR = 'FETCH_PRODUCT_DETAIL_COLOR';
export const FETCH_PRODUCT_DETAIL_RUN = 'FETCH_PRODUCT_DETAIL_RUN';
export const FETCH_PRODUCT_DETAIL_WAREHOUSE = 'FETCH_PRODUCT_DETAIL_WAREHOUSE';
// #endregion

// #region Shipping method
export const FETCH_SHIPPING_METHODS = 'FETCH_SHIPPING_METHODS';
// #endregion

// #region FILTERS
export const FETCH_FILTERS = 'FETCH_FILTERS';
export const FILTER_PRICE = 'FILTER_PRICE';
// #endregion

// #region Cart
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const FETCH_CART_ITEMS = 'FETCH_CART_ITEMS';
export const FETCH_CART_ITEMS_COUNT = 'FETCH_CART_ITEMS_COUNT';
// #endregion

// #region Compare
export const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
export const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';
// #endregion

export const ADD_WS_MSG = 'ADD_WS_MSG';
export const CLEAR_WS_MSG = 'CLEAR_WS_MSG';

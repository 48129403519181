import React, { useMemo, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import {
    Autocomplete,
    CircularProgress,
    TextField,
    ListItemText,
    ListItem,
    IconButton,
    ListItemAvatar,
    Avatar,
    Popover,
    Paper,
    MenuItem,
    MenuList,
} from '@mui/material';
import _ from 'lodash';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { siteAccessRestrictedDueToNoLogin, getQueryFilter } from 'util/utils';

import { useLocation } from 'react-router-dom';
import { fetchProductList } from 'network/products';
import { useSelector } from 'react-redux';
import ComponentNames from 'util/ComponentNames';
import { ArrowDropDown } from '@mui/icons-material';

export default function SearchTextField({
    width = 260,
    size = 'small',
    placeholder,
    onSearchKeyword,
    onSelectProduct,
}) {
    const websiteConfiguration = useSelector((store) => store.websiteConfiguration);
    const auth = useSelector((store) => store.auth);
    const [openOptions, setOpenOptions] = useState(false);
    const [options, setOptions] = useState([]);

    const [openSearchOptions, setOpenSearchOptions] = useState(false);
    const [searchByContains, setSearchByContains] = useState(true);

    const searchOptionsRef = useRef(null);

    const location = useLocation();

    const queries = getQueryFilter();

    const [inputValue, setInputValue] = useState(queries.keyword ? queries.keyword : '');
    const [textValue, setTextValue] = useState('');
    const [loading, setLoading] = useState(false);

    const searchProductCallback = useMemo(
        () =>
            _.debounce(async (inputValue, textValue) => {
                // Initial render
                // wait until we have at least 3 characters to get meaningful results
                if (_.isEmpty(textValue) || textValue.length < 3) {
                    setOptions([
                        {
                            __insufficient_text: true,
                            __disable_select: true,
                        },
                    ]);
                    return;
                }

                setLoading(true);
                const result = await fetchProductList({
                    search_by_contains: searchByContains,
                    keyword: inputValue,
                    limit: 20,
                });

                if (!_.isEmpty(result) && !_.isEmpty(result.products)) {
                    setOptions([...result.products]);
                } else {
                    setOptions([
                        {
                            __no_result: true,
                            __disable_select: true,
                        },
                    ]);
                }
                setLoading(false);
            }, 400),
        [searchByContains],
    );

    useEffect(() => {
        const queries = getQueryFilter();
        if (!queries.keyword) {
            setInputValue('');
            setTextValue('');
        }
    }, [location.search]);

    useEffect(() => {
        if (_.isEmpty(inputValue)) {
            setOptions([]);
            setLoading(false);
            return undefined;
        }
        searchProductCallback(inputValue, textValue);

        return () => {
            searchProductCallback.cancel();
        };
    }, [inputValue, textValue, searchProductCallback]);

    useEffect(() => {
        if (!openOptions) {
            setOptions([]);
        }
    }, [openOptions]);

    if (siteAccessRestrictedDueToNoLogin(websiteConfiguration, auth)) {
        return null;
    }

    const handleToggleSearchOptions = () => {
        setOpenSearchOptions(!openSearchOptions);
    };

    // Used when user presses enter key or clicks on search icon
    const searchItemNum = () => {
        if (textValue) {
            onSearchKeyword(textValue);
            setOpenOptions(false);
        }
    };

    return (
        // Mobile Chrome issue: keycode is undefined, but form fixes it
        <>
            <form
                onSubmit={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <Autocomplete
                    id={ComponentNames.SearchTextfieldID}
                    freeSolo
                    inputValue={inputValue}
                    sx={{ width: width ? width : 260 }}
                    size={size}
                    open={openOptions}
                    // Don't focus on autocomplete after search
                    blurOnSelect
                    onOpen={() => {
                        setOpenOptions(true);
                    }}
                    onClose={() => {
                        setOpenOptions(false);
                    }}
                    onChange={(event, value) => {
                        if (event.key === 'Enter' && _.isString(value)) {
                            searchItemNum();
                        } else if (!_.isEmpty(value) && !value.__disable_select) {
                            onSelectProduct(value.item_num, value);
                        }
                    }}
                    onInputChange={(event, newInputValue, reason) => {
                        if (reason === 'clear') {
                            setInputValue('');
                        } else {
                            setInputValue(newInputValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.description1 === value.description1
                    }
                    filterOptions={(x) => x}
                    getOptionLabel={(option) => {
                        if (_.isString(option)) return option;
                        // Clear text if user clicks on an option
                        return '';
                    }}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            size={size ? size : 'small'}
                            placeholder={placeholder ?? 'Search'}
                            value={textValue}
                            onChange={(e) => setTextValue(e.target.value)}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <IconButton
                                            size="small"
                                            // sx={{ p: '2px' }}
                                            onClick={() => {
                                                searchItemNum();
                                            }}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                        <IconButton
                                            size="small"
                                            sx={{ p: 0 }}
                                            ref={searchOptionsRef}
                                            onClick={handleToggleSearchOptions}
                                        >
                                            <ArrowDropDown fontSize="4px" />
                                        </IconButton>
                                    </>
                                ),
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? (
                                            <CircularProgress color="inherit" size={20} />
                                        ) : null}

                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                    renderOption={(props, option) => {
                        if (option.__insufficient_text) {
                            return (
                                <ListItem {...props}>
                                    <ListItemText
                                        secondary={'Please enter 3 or more characters.'}
                                    />
                                </ListItem>
                            );
                        }
                        if (option.__no_result) {
                            return (
                                <ListItem {...props}>
                                    <ListItemText secondary={'No results found.'} />
                                </ListItem>
                            );
                        }
                        const descriptionMatches = match(option.description1, inputValue);
                        const descriptionParts = parse(option.description1, descriptionMatches);

                        const itemNumberMatches = match(option.item_num, inputValue);
                        const itemNumberParts = parse(option.item_num, itemNumberMatches);

                        return (
                            <ListItem {...props}>
                                <ListItemAvatar>
                                    <Avatar
                                        src={option.images?.__general?.small}
                                        variant="rounded"
                                    />
                                </ListItemAvatar>

                                <ListItemText
                                    primary={descriptionParts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                    secondary={itemNumberParts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{ fontWeight: part.highlight ? 700 : 400 }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                    onClick={() => {
                                        onSelectProduct(option.item_num, option);
                                    }}
                                />
                            </ListItem>
                        );
                    }}
                />
            </form>
            <Popover
                open={openSearchOptions}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                anchorEl={searchOptionsRef.current}
                onClose={handleToggleSearchOptions}
            >
                <Paper>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                setSearchByContains(true);
                                setOpenSearchOptions(false);
                            }}
                            selected={searchByContains}
                        >
                            {'Contains'}
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setSearchByContains(false);
                                setOpenSearchOptions(false);
                            }}
                            selected={!searchByContains}
                        >
                            {'Starts With'}
                        </MenuItem>
                    </MenuList>
                </Paper>
            </Popover>
        </>
    );
}

import React, { Suspense, lazy, Fragment, isValidElement } from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import _ from 'lodash';

import {
    siteAccessRestrictedDueToNoLogin,
    UrlEnum,
    ServiceTypeEnum,
    isPriceCheckerWebsite,
    WebsiteRestrictionEnum,
    isB2BWebsite,
    scrollToTop,
    isSalesRepWebsite,
    isPointOfSaleWebsite,
    isLoggedIn,
    isUsingPayments,
    siteAllowsOrdering,
} from './util/utils';
import PrivateRoute from 'components/Route/PrivateRoute';
import RestrictSiteAccessRoute from 'components/Route/RestrictSiteAccessRoute';
import RestrictProductDisplaySiteAccessRoute from 'components/Route/RestrictProductDisplaySiteAccessRoute';

import PavoTheme from './PavoTheme';

import WebsocketContext from 'websocket/WebsocketContext';
import WebsocketClient from 'websocket/WebsocketClient';
import {
    fetchUser,
    fetchEnabledService,
    fetchWebsiteConfiguration,
    fetchCategories,
    fetchFilters,
    setSnackbar,
    logout,
    addWebsocketMsg,
} from './actions';
import history from './history';

import WebFont from 'webfontloader';
import ReactGA from 'react-ga4';
import PavoLoadingSpinner from 'components/common/PavoLoadingSpinner';
import PavoSnackbar from 'components/common/PavoSnackbar';
import HeaderOne from 'components/HeaderBar';
import Footer from 'components/common/footer';
import ComponentNames from 'util/ComponentNames';
import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import SelectedCustomerRoute from 'components/Route/SelectedCustomerRoute';
import AccountSettings from 'pages/Mercury/account/AccountSettings';

// Code splitting here, so we can lazy load components that aren't immediately needed.
// https://reactjs.org/docs/code-splitting.html

// layout
const DefaultLayout = lazy(() => import('components/layouts/default/main'));
const PriceChecker = lazy(() => import('./pages/PriceChecker'));
const PointofSale = lazy(() => import('./pages/PointOfSale'));
const Products = lazy(() => import('./pages/Mercury/products'));
const ProductDetail = lazy(() => import('./pages/Mercury/product'));
const CategoryDetail = lazy(() => import('./pages/Mercury/category_detail'));

// Account Pages
const OrderHistory = lazy(() => import('./pages/Mercury/account/OrderHistory'));
const PaymentHistory = lazy(() => import('./pages/Mercury/account/PaymentHistory'));
const InvoiceHistory = lazy(() => import('./pages/Mercury/account/InvoiceHistory'));
const PaymentMethods = lazy(() => import('./pages/Mercury/PaymentMethods'));
const ReservedList = lazy(() => import('./pages/Mercury/ReservedList'));
const OrderDetail = lazy(() => import('./pages/Mercury/order/detail'));
const InvoiceDetail = lazy(() => import('./pages/Mercury/invoice/detail'));
const AccountProfile = lazy(() => import('./pages/Mercury/AccountProfile'));
const CustomerList = lazy(() => import('./pages/Mercury/CustomerList'));
const CustomerDetail = lazy(() => import('./pages/Mercury/CustomerDetail'));
const SalesRepDashboard = lazy(() => import('./pages/Mercury/SalesDashboard'));

// Features
const Cart = lazy(() => import('./pages/Mercury/cart'));
const Checkout = lazy(() => import('./pages/Mercury/checkout'));
const OrderSuccess = lazy(() => import('./pages/Mercury/order/success'));

// custom
const CustomPage = lazy(() => import('./pages/Mercury/CustomPage'));

// Extra Pages
const AboutUs = lazy(() => import('./pages/Mercury/about-us'));
const TermsConditions = lazy(() => import('./pages/Mercury/terms-conditions'));
const PrivacyPolicy = lazy(() => import('./pages/Mercury/privacy-policy'));
const PageNotFound = lazy(() => import('./pages/Mercury/404'));
const Login = lazy(() => import('./pages/Mercury/LoginCard'));
const Register = lazy(() => import('./pages/Mercury/register'));
const ResetPassword = lazy(() => import('./pages/Mercury/ResetPassword'));
const Contact = lazy(() => import('./pages/Mercury/contact'));
const FAQ = lazy(() => import('./pages/Mercury/faq'));
const KeyboardShortcuts = lazy(() => import('./pages/Mercury/KeyboardShortcuts'));

function flatten(target, children) {
    children.forEach((child) => {
        if (isValidElement(child)) {
            if (child.type === Fragment) {
                flatten(target, child.props.children);
            } else {
                target.push(child);
            }
        } else if (Array.isArray(child)) {
            flatten(target, child);
        }
    });
}

// Allows Switch to work with React.Fragment
const FragmentSupportingSwitch = ({ children }) => {
    const flattenedChildren = [];
    flatten(flattenedChildren, children);
    return React.createElement.apply(React, [Switch, null].concat(flattenedChildren));
};

class App extends React.Component {
    constructor(props) {
        super(props);

        this.socket = new WebsocketClient(
            (requestName, message, data = null, error) => {
                props.addWebsocketMsg({
                    requestName,
                    message,
                    data,
                    error,
                });
            },
            (message, severity = 'error') => {
                props.setSnackbar({
                    severity,
                    message,
                });
            },
        );

        this.state = {
            showAboutUs: null,
            showContactUs: null,
            showPrivacyPolicy: null,
            showTermsAndConditions: null,
            customRoutes: null,
        };

        this.keyPressHandler = null;
    }

    // This should be run by keyPressHandler, with that being context for this component
    handleKeyShortcuts(e, that) {
        const selectSearchBar = (e) => {
            e.preventDefault();
            const searchElement = document.getElementById(ComponentNames.SearchTextfieldID);

            if (searchElement) {
                searchElement.select();
            }
        };

        if (e.ctrlKey && e.key === 'k') {
            scrollToTop();
            selectSearchBar(e);
        } else if (e.altKey) {
            const serviceType = that?.props?.serviceType,
                auth = that?.props?.auth;

            // Require B2B or Sales and user to be logged in
            if (
                (!isB2BWebsite(serviceType) && !isSalesRepWebsite(serviceType)) ||
                !isLoggedIn(auth)
            ) {
                return;
            }

            if (e.key == 'c') {
                e.preventDefault();
                const element = document.getElementById(ComponentNames.QuickSelectButton);
                if (element) {
                    element.click();
                }
            } else if (e.key == 'o') {
                e.preventDefault();
                history.push(UrlEnum.ORDER_HISTORY);
            } else if (e.key == 'i') {
                e.preventDefault();
                history.push(UrlEnum.INVOICE_HISTORY);
            } else if (e.key == 'p' || e.key == 'a') {
                e.preventDefault();
                history.push(UrlEnum.PRODUCTS);
            } else if (e.key == 's') {
                e.preventDefault();
                history.push(UrlEnum.SHOPPING_CART);
            } else if (e.key == 'd') {
                e.preventDefault();
                history.push(UrlEnum.CHECKOUT);
            } else if (e.key == 'k') {
                scrollToTop();
                selectSearchBar(e);
            }
        }
    }

    async componentDidMount() {
        // Use function to keep context of this component and provide to handler
        this.keyPressHandler = (event) => {
            this.handleKeyShortcuts(event, this);
        };

        addEventListener('keydown', this.keyPressHandler);

        const enabledService = this.props.fetchEnabledService();

        await Promise.all([this.props.fetchUser(true), this.props.fetchWebsiteConfiguration()]);

        if (this.props.auth) {
            // automatically log out if we are on the wrong site
            if (
                enabledService === ServiceTypeEnum.SalesRep &&
                this.props.auth.login_type === 'customer'
            ) {
                this.props.logout();
            }

            if (
                enabledService === ServiceTypeEnum.B2B &&
                this.props.auth.login_type === 'sales_rep'
            ) {
                this.props.logout();
            }
        }

        if (this.props.websiteConfiguration) {
            const { websiteConfiguration } = this.props;
            const { font_family, primary_color, favicon, footer_content, analytics } =
                websiteConfiguration;

            if (!_.isEmpty(analytics)) {
                const types = Object.keys(analytics);
                types.forEach((type) => {
                    const { key } = analytics[type];
                    switch (key) {
                        case 'klaviyo':
                            {
                                const script = document.createElement('script');
                                script.src = `https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=${key}`;
                                script.async = true;
                                script.type = 'text/javascript';
                                document.body.appendChild(script);
                            }
                            break;
                        case 'google':
                            ReactGA.initialize(key);
                            break;
                        default:
                            break;
                    }
                });
            }

            const font = font_family ? font_family : 'Lato';
            WebFont.load({
                google: {
                    families: [`${font}:300,400,700,900`, 'sans-serif'],
                },
            });
            document.documentElement.style.setProperty('--primary-font', font);

            if (primary_color) {
                document.documentElement.style.setProperty('--theme-primary-color', primary_color);
            }

            if (!_.isEmpty(favicon)) {
                const faviconDocument = document.getElementById('favicon');
                faviconDocument.href = favicon.standard;
            }
            const state = {};
            const customRoutes = [];
            footer_content.forEach((e) => {
                switch (e.type) {
                    case 'about_us':
                        state.showAboutUs = e.display_content;
                        break;
                    case 'contact_us':
                        state.showContactUs = e.display_content;
                        break;
                    case 'privacy_policy':
                        state.showPrivacyPolicy = e.display_content;
                        break;
                    case 'terms_conditions':
                        state.showTermsAndConditions = e.display_content;
                        break;
                    default:
                        if (e.custom && !_.isEmpty(e.slug) && e.display_content) {
                            customRoutes.push(e);
                        }
                        break;
                }
            });
            state.customRoutes = customRoutes;

            if (!siteAccessRestrictedDueToNoLogin(websiteConfiguration)) {
                this.props.fetchFilters();
                this.props.fetchCategories();
            }
            this.setState(state);
        }
        if (this.props.auth && !this.props.auth.agree_terms) {
            this.props.logout();
        }
    }

    // Connect to websocket once logged in
    componentDidUpdate(prevProps) {
        const { auth: prevAuth } = prevProps;
        const { auth } = this.props;

        if (auth && !prevAuth) {
            const { id } = auth;
            this.socket.connectWS(id);
        }
    }

    componentWillUnmount() {
        removeEventListener('keydown', this.keyPressHandler);
    }

    renderLoadingBackdrop() {
        const { backdrop = {} } = this.props;
        if (_.isEmpty(backdrop)) {
            return null;
        }

        const { message = 'Loading...', ...backdropProps } = backdrop;

        return (
            <Backdrop open {...backdropProps}>
                <Box
                    sx={{
                        display: 'block',
                        color: 'white',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            mb: 1,
                            ml: 'auto',
                            mr: 'auto',
                        }}
                    >
                        <CircularProgress color="inherit" />
                    </Box>
                    <Typography textAlign={'center'} variant="h6" sx={{ color: 'white' }}>
                        {message}
                    </Typography>
                </Box>
            </Backdrop>
        );
    }

    renderSnackbar() {
        const { snackbar } = this.props;
        if (_.isEmpty(snackbar)) {
            return null;
        }
        return (
            <PavoSnackbar
                open
                message={snackbar.message}
                severity={snackbar.severity}
                position={snackbar.position}
                onClose={() => this.props.setSnackbar()}
            />
        );
    }

    renderHomeRoute() {
        const { serviceType, websiteConfiguration } = this.props;
        const path = UrlEnum.HOME;

        switch (serviceType) {
            case ServiceTypeEnum.PointOfSale:
                return <PrivateRoute exact path={path} component={PointofSale} />;
            case ServiceTypeEnum.SalesRep:
                return <RestrictSiteAccessRoute exact path={path} component={Products} />;
            case ServiceTypeEnum.PriceChecker:
                return <Route exact path={path} component={PriceChecker} />;
            case ServiceTypeEnum.B2B:
            default:
                if (
                    websiteConfiguration.website_restriction ===
                        WebsiteRestrictionEnum.FullRestriction ||
                    !siteAllowsOrdering(websiteConfiguration)
                ) {
                    return <RestrictSiteAccessRoute exact path={path} component={DefaultLayout} />;
                } else {
                    return <Route exact path={path} component={DefaultLayout} />;
                }
        }
    }

    render() {
        const { auth, websiteConfiguration, pavoGlobalFilter, serviceType } = this.props;
        const {
            showPrivacyPolicy,
            showTermsAndConditions,
            showAboutUs,
            showContactUs,
            customRoutes,
        } = this.state;

        if (!websiteConfiguration) {
            return null;
        }

        if (customRoutes == null) {
            // not finished loading yet. if we finished loading, customRoutes would be an empty array
            return null;
        }

        if (auth == null) {
            // null auth means it's not loaded yet, false means we're definitely logged out
            return null;
        }

        // this needs to come after the auth and require_login check, since the back-end won't return the filters if we require a login

        if (pavoGlobalFilter == null) {
            return null;
        }

        // Hide the routes if not these systems
        const b2bWebsite = isB2BWebsite(serviceType),
            salesWebsite = isSalesRepWebsite(serviceType),
            pointOfSaleWebsite = isPointOfSaleWebsite(serviceType),
            priceCheckerWebsite = isPriceCheckerWebsite(serviceType);

        const b2bOrSales = b2bWebsite || salesWebsite;

        const hasLogin = b2bOrSales || pointOfSaleWebsite;

        return (
            <PavoTheme>
                <WebsocketContext.Provider value={{ websocketClient: this.socket }}>
                    <Router basename={'/'} history={history}>
                        {!pointOfSaleWebsite && <HeaderOne logoName={'logo.png'} />}
                        <Suspense
                            fallback={
                                <Box sx={{ my: 10 }}>
                                    <PavoLoadingSpinner />
                                </Box>
                            }
                        >
                            <FragmentSupportingSwitch>
                                {this.renderHomeRoute()}

                                {hasLogin && <Route exact path={UrlEnum.LOGIN} component={Login} />}

                                {b2bOrSales && (
                                    <>
                                        {/*Routes For Extra Pages*/}
                                        {showPrivacyPolicy && (
                                            <Route
                                                path={UrlEnum.PRIVACY_POLICY}
                                                component={PrivacyPolicy}
                                            />
                                        )}
                                        {showTermsAndConditions && (
                                            <Route
                                                path={UrlEnum.TERMS_CONDITIONS}
                                                component={TermsConditions}
                                            />
                                        )}
                                        {showAboutUs && (
                                            <Route path={UrlEnum.ABOUT_US} component={AboutUs} />
                                        )}
                                        <Route
                                            path={UrlEnum.NOT_FOUND_404}
                                            component={PageNotFound}
                                        />
                                        {b2bWebsite && (
                                            <Route path={UrlEnum.REGISTER} component={Register} />
                                        )}
                                        <Route
                                            path={`${UrlEnum.RESET_PASSWORD_NID}/:email/:token`}
                                            component={ResetPassword}
                                        />
                                        {showContactUs && (
                                            <Route path={UrlEnum.CONTACT} component={Contact} />
                                        )}
                                        <Route path={UrlEnum.FAQ} component={FAQ} />
                                        <Route
                                            path={UrlEnum.KEYBOARD_SHORTCUTS}
                                            component={KeyboardShortcuts}
                                        />

                                        {customRoutes.map((e, index) => (
                                            <Route
                                                key={index}
                                                path={`/${e.slug}`}
                                                render={(props) => (
                                                    <CustomPage {...props} type={e.type} />
                                                )}
                                            />
                                        ))}
                                        {/* These are intentionally placed last since react-router goes down the line. if we are not logged in automatically reroute to login */}
                                        {/*Routes for account settings*/}
                                        <PrivateRoute
                                            exact
                                            path={UrlEnum.ORDER_HISTORY}
                                            component={OrderHistory}
                                        />

                                        <PrivateRoute
                                            exact
                                            path={UrlEnum.INVOICE_HISTORY}
                                            component={InvoiceHistory}
                                        />
                                        <PrivateRoute
                                            path={UrlEnum.ACCOUNT_SETTINGS}
                                            component={AccountSettings}
                                        />
                                        {isUsingPayments(websiteConfiguration) && (
                                            <>
                                                <SelectedCustomerRoute
                                                    exact
                                                    path={UrlEnum.PAYMENT_METHODS}
                                                    component={PaymentMethods}
                                                />
                                                <SelectedCustomerRoute
                                                    exact
                                                    path={UrlEnum.PAYMENT_HISTORY}
                                                    component={PaymentHistory}
                                                />
                                            </>
                                        )}
                                        {siteAllowsOrdering(websiteConfiguration) && (
                                            <>
                                                <PrivateRoute
                                                    exact
                                                    path={UrlEnum.RESERVED_CART}
                                                    component={ReservedList}
                                                />
                                                <PrivateRoute
                                                    path={UrlEnum.QUICK_ADD_PRODUCTS}
                                                    component={Products}
                                                />
                                                <PrivateRoute
                                                    path={UrlEnum.SHOPPING_CART}
                                                    component={Cart}
                                                />
                                                <PrivateRoute
                                                    path={UrlEnum.CHECKOUT}
                                                    component={Checkout}
                                                />
                                                <PrivateRoute
                                                    path={UrlEnum.ORDER_SUCCESS}
                                                    component={OrderSuccess}
                                                />
                                                <RestrictSiteAccessRoute
                                                    path={UrlEnum.CATEGORY_GETTER(':id')}
                                                    component={CategoryDetail}
                                                />
                                                {/*Routes For Features (Product Collection) */}
                                                <RestrictProductDisplaySiteAccessRoute
                                                    path={`${UrlEnum.PRODUCTS}`}
                                                    component={Products}
                                                />
                                                {/*Routes For Single Product*/}
                                                <RestrictProductDisplaySiteAccessRoute
                                                    path={`${UrlEnum.PRODUCT_NID}/:id`}
                                                    component={ProductDetail}
                                                />
                                            </>
                                        )}
                                        <PrivateRoute
                                            path={`${UrlEnum.ORDER_DETAIL_NID}/:id`}
                                            component={OrderDetail}
                                        />
                                        <PrivateRoute
                                            path={`${UrlEnum.INVOICE_DETAIL_NID}/:id`}
                                            component={InvoiceDetail}
                                        />
                                        {b2bWebsite && (
                                            <PrivateRoute
                                                path={UrlEnum.ACCOUNT_PROFILE}
                                                component={AccountProfile}
                                            />
                                        )}
                                        <PrivateRoute
                                            path={UrlEnum.CUSTOMER_LIST}
                                            component={CustomerList}
                                        />
                                        {salesWebsite && (
                                            <>
                                                <PrivateRoute
                                                    path={UrlEnum.ACCOUNT_DASHBOARD}
                                                    component={SalesRepDashboard}
                                                />
                                                <PrivateRoute
                                                    path={`${UrlEnum.CUSTOMER_DETAIL_NID}/:id`}
                                                    component={CustomerDetail}
                                                />
                                            </>
                                        )}
                                    </>
                                )}

                                <Route component={PageNotFound} />
                            </FragmentSupportingSwitch>
                        </Suspense>
                        {!priceCheckerWebsite && !pointOfSaleWebsite && (
                            <Footer logoName={'logo.png'} />
                        )}
                        {this.renderLoadingBackdrop()}
                        {this.renderSnackbar()}
                    </Router>
                </WebsocketContext.Provider>
            </PavoTheme>
        );
    }
}

function mapStateToProps({
    auth,
    websiteConfiguration,
    serviceType,
    pavoGlobalFilter,
    snackbar,
    backdrop,
}) {
    return { auth, websiteConfiguration, serviceType, pavoGlobalFilter, snackbar, backdrop };
}

export default connect(mapStateToProps, {
    fetchUser,
    fetchEnabledService,
    fetchWebsiteConfiguration,
    fetchFilters,
    fetchCategories,
    setSnackbar,
    logout,
    addWebsocketMsg,
})(App);

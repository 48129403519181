import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import FilterCategoryRow from 'components/products/FilterCategoryRow';

function sortCategoriesList(catList) {
    catList.sort((aObj, bObj) => {
        let a = aObj?.category?.sequence;
        let b = bObj?.category?.sequence;

        if (a == null) a = 9999999;
        if (b == null) b = 9999999;
        return Number(a) - Number(b);
    });
}

// Set category object active try if categoryCode is found top to bottom of tree
function setOpenRecursively(categoryCode, categoryObjectList) {
    if (_.isEmpty(categoryObjectList)) return false;

    for (const categoryObject of categoryObjectList) {
        const { category, children } = categoryObject;
        if (categoryCode === category.slug) {
            categoryObject.selected = true;
            return true;
        }

        if (!_.isEmpty(children)) {
            if (setOpenRecursively(categoryCode, children)) {
                categoryObject.open = true;
                return true;
            }
        }
    }
    return false;
}

function CategoryList({ queryFilter = {} }) {
    const { categories } = useSelector((store) => store.category);
    const [categoriesTree, setCategoriesTree] = useState([]);

    useEffect(() => {
        if (_.isEmpty(categories)) return;

        const categoriesTree = [];
        // Keys is the parent ID, Value is list of children categories
        const parentMap = new Map();

        categories.forEach((category) => {
            // build parentMap
            const { category_id } = category;
            if (!parentMap.has(category_id)) {
                parentMap.set(category_id, []);
            }

            const parentId = category.parent_category_id;
            if (parentId) {
                if (!parentMap.has(parentId)) {
                    parentMap.set(parentId, []);
                }
                const childrenList = parentMap.get(parentId);
                childrenList.push(category);
            }
        });
        // Uses recursion to further create children of the same object
        function createCategoryObject(category, level) {
            const { category_id } = category;
            let children = [];
            if (level <= 2) {
                const categoryChildren = parentMap.get(category_id);
                children = categoryChildren.map((category) =>
                    createCategoryObject(category, level + 1),
                );
                sortCategoriesList(children);
            }
            const obj = {
                open: false,
                selected: false,
                category_id,
                children,
                category,
                level,
            };
            return obj;
        }

        categories.forEach((category) => {
            if (!category.parent_category_id) {
                const categoryObj = createCategoryObject(category, 1);
                categoriesTree.push(categoryObj);
            }
        });
        sortCategoriesList(categoriesTree);

        const queryCategoryCode = queryFilter.category;
        if (queryCategoryCode) {
            setOpenRecursively(queryCategoryCode, categoriesTree);
        }

        setCategoriesTree(categoriesTree);
    }, [categories, queryFilter]);

    return (
        <>
            {categoriesTree.map((categoryObj, index) => {
                const { category_id } = categoryObj;
                return (
                    <FilterCategoryRow
                        level={1}
                        categoryObject={categoryObj}
                        key={`category-row-${category_id}`}
                    />
                );
            })}
        </>
    );
}

export default CategoryList;

import { FETCH_CATEGORIES } from '../constants/ActionTypes';

const initState = {
    categories: null,
};

export default function categoriesReducer(state = initState, action) {
    switch (action.type) {
        case FETCH_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
            };
        default:
            return state;
    }
}

import React from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import App from './App';
import * as serviceWorker from './serviceWorker';

// Import custom components
import Root from 'root';

ReactDOM.render(
    <Root>
        <IntlProvider locale={navigator.language}>
            <App />
        </IntlProvider>
    </Root>,

    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// NOTE: We are no longer using the service worker because it is causing issues with the rest of the B2B/Sales Rep website. Updates to the site are not being pushed to the client's browsers and they must do a hard refresh if we use the service worker. This should be resolved by moving offline functions to an app or a separate project.
serviceWorker.unregister();

import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ChangePasswordDialog from 'components/Dialog/ChangePasswordDialog';
import PavoHelmet from 'components/common/PavoHelmet';
import AccountDetail from 'components/layouts/AccountDetail';
import {
    Button,
    Card,
    CardContent,
    Box,
    Typography,
    Divider,
    FormGroup,
    FormControlLabel,
    Switch,
    CardActions,
} from '@mui/material';
import { isSalesRepWebsite, UrlEnum } from 'util/utils';
import PavoContent from 'components/common/PavoContent';
import PavoDetailTextField from 'components/TextField/PavoDetailTextField';
import { LoadingButton } from '@mui/lab';
import { saveWebCustomer } from 'network/user';
import { fetchUser, setSnackbar } from 'actions';

export default function AccountSettings() {
    const [changePasswordDialogOpened, setChangePasswordDialogOpened] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [name, setName] = React.useState('');
    const [resetCustomerAfterOrder, setResetCustomerAfterOrder] = React.useState(false);
    const [allowInstantOrder, setAllowInstantOrder] = React.useState(false);
    const [needsReload, setNeedsReload] = React.useState(true);
    const [loadingSave, setLoadingSave] = React.useState(false);
    const [saveErrorMessage, setSaveErrorMessage] = React.useState(null);

    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector((store) => store.auth);
    const serviceType = useSelector((store) => store.serviceType);

    React.useEffect(() => {
        if (auth && needsReload) {
            setEmail(auth.username);
            setName(auth.customer_name);
            setResetCustomerAfterOrder(auth.reset_customer_after_order);
            setAllowInstantOrder(auth.allow_instant_order);
            setNeedsReload(false);
        }
    }, [auth, needsReload]);

    const handleSave = async () => {
        setLoadingSave(true);
        setSaveErrorMessage(null);
        try {
            await saveWebCustomer({
                customer_name: name,
                username: email,
                reset_customer_after_order: resetCustomerAfterOrder,
                allow_instant_order: allowInstantOrder,
            });
            dispatch(fetchUser());
            dispatch(setSnackbar({ message: 'Account settings saved.', severity: 'success' }));
        } catch (error) {
            console.error(error);
            setSaveErrorMessage(error.message);
            dispatch(setSnackbar({ message: 'Error saving account settings.', severity: 'error' }));
        }
        setLoadingSave(false);
    };

    if (auth === false) {
        history.push(UrlEnum.LOGIN, { login_redirect_url: location.href });
        return null;
    }

    const renderMainContent = () => {
        const { sales_rep_code } = auth;

        return (
            <>
                <Typography variant="h6" gutterBottom>
                    {'Account Settings'}
                </Typography>
                <Typography>{'These settings only apply to you.'}</Typography>

                <Card sx={{ mt: 2, maxWidth: '480px' }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            {'Contact Information'}
                        </Typography>

                        <Box>
                            {isSalesRepWebsite(serviceType) && (
                                <Typography gutterBottom>
                                    {
                                        'We will soon be requiring logins by email address and will not be using the OMS username. Please make sure your email address is up to date.'
                                    }
                                </Typography>
                            )}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSave();
                                }}
                            >
                                <PavoDetailTextField
                                    label={'Name'}
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <PavoDetailTextField
                                    label="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {!isSalesRepWebsite(serviceType) && (
                                    <Button
                                        sx={{ mt: 1 }}
                                        variant="contained"
                                        onClick={() => setChangePasswordDialogOpened(true)}
                                    >
                                        {'Change password...'}
                                    </Button>
                                )}
                            </form>
                        </Box>

                        {isSalesRepWebsite(serviceType) && (
                            <>
                                <Divider sx={{ mt: 2, mb: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    {'Sales Rep Information'}
                                </Typography>
                                <Typography
                                    gutterBottom
                                >{`Sales Rep Code: ${sales_rep_code}`}</Typography>
                                <Typography>
                                    {'Orders will be placed with this sales rep code.'}
                                </Typography>
                                <Divider sx={{ mt: 2, mb: 2 }} />
                                <Typography variant="h6" gutterBottom>
                                    {'Website Settings'}
                                </Typography>
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={resetCustomerAfterOrder}
                                                onChange={(e) =>
                                                    setResetCustomerAfterOrder(e.target.checked)
                                                }
                                            />
                                        }
                                        label={'Clear selected customer after order is placed'}
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={allowInstantOrder}
                                                onChange={(e) =>
                                                    setAllowInstantOrder(e.target.checked)
                                                }
                                            />
                                        }
                                        label={'Allow Instant Order'}
                                    />
                                </FormGroup>
                            </>
                        )}
                    </CardContent>
                    <CardActions>
                        <Box display="block">
                            {saveErrorMessage && (
                                <Typography color="error" gutterBottom>
                                    {saveErrorMessage}
                                </Typography>
                            )}{' '}
                            <LoadingButton
                                loading={loadingSave}
                                onClick={handleSave}
                                variant="contained"
                            >
                                {'Save'}
                            </LoadingButton>
                        </Box>
                    </CardActions>
                </Card>
            </>
        );
    };

    return (
        <PavoContent id="main-page-body">
            <PavoHelmet title="Account Settings" />
            <AccountDetail active="settings">{renderMainContent()}</AccountDetail>
            <ChangePasswordDialog
                open={changePasswordDialogOpened}
                onClose={() => setChangePasswordDialogOpened(false)}
            />
        </PavoContent>
    );
}

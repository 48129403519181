import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Alert } from '@mui/material';

const AutoHideDuration = 6000; // 6 seconds

export default function PavoSnackbar(props) {
    const { position = 'bottom-center', open } = props;

    let vertical = 'bottom';
    let horizontal = 'center';

    switch (position) {
        case 'top-right':
            vertical = 'top';
            horizontal = 'right';
            break;
        case 'top-left':
            vertical = 'top';
            horizontal = 'left';
            break;
        case 'top-center':
            vertical = 'top';
            horizontal = 'center';
            break;
        case 'bottom-right':
            vertical = 'bottom';
            horizontal = 'right';
            break;
        case 'bottom-left':
            vertical = 'bottom';
            horizontal = 'left';
            break;
        case 'bottom-center':
        case undefined:
            break;
        default:
            // vertical and horizontal variables are already set
            console.error(`Error: position props '${position}' is not a recognized type.`);
            break;
    }

    const severity = props.severity ? props.severity : 'info';

    // sometimes the message props will be null when on initial set, causing an error to be thrown in console
    const message = props.message ? props.message : '';

    return (
        <Snackbar
            autoHideDuration={AutoHideDuration}
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            onClose={props.onClose}
            key={vertical + horizontal}
        >
            <Alert onClose={props.onClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

PavoSnackbar.propTypes = {
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    severity: PropTypes.string,
    position: PropTypes.string,
};

import configuration from './configuration';
import axios from 'axios';

export const fetchWebsiteSocialMedia = async () => {
    try {
        const object = configuration.fetchWebsiteObject();
        const response = await axios.get('/api/social_media', object);

        return response.data;
    } catch (error) {
        console.error('Fetching social media error', error);
    }
};

export const fetchWebsiteContent = async (params) => {
    try {
        const object = configuration.fetchWebsiteObject(params);
        const response = await axios.get('/api/website_pages', object);
        return response.data;
    } catch (error) {
        console.error('Shipping method Get Error: ', error);
    }
};

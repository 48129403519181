import React from 'react';

import { ListItem, ListItemText, ListItemButton } from '@mui/material';

export default function SubmenuListItem(props) {
    const { showHeader, text, onClick } = props;
    return (
        <ListItem disablePadding>
            <ListItemButton sx={showHeader ? { pl: 4 } : {}} onClick={() => onClick()}>
                <ListItemText primary={text} />
            </ListItemButton>
        </ListItem>
    );
}

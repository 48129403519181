import React from 'react';
import PropTypes from 'prop-types';
import PavoModal from 'components/Modal/PavoModal';
import { useDispatch } from 'react-redux';
import { fetchCart, fetchUser, setSnackbar } from 'actions';
import { saveWebCustomer } from 'network/user';

function ClearSelectedCustomerModal(props) {
    const dispatch = useDispatch();
    const [loadingClear, setLoadingClear] = React.useState(false);

    const handleClearSelectedCustomer = async () => {
        setLoadingClear(true);
        await saveWebCustomer({
            customer_id: null,
            store_num: '',
        });
        await dispatch(fetchUser());
        dispatch(
            setSnackbar({
                message: 'You are no longer placing orders for a customer.',
                severity: 'success',
            }),
        );
        setLoadingClear(false);
        dispatch(fetchCart());
        props.onClose();
    };

    return (
        <PavoModal
            open={props.open}
            onClose={() => props.onClose()}
            title={'Confirm Clear Customer'}
            body={
                'Are you sure you want to clear the selected customer? You will need to select a new customer in order to place orders.'
            }
            onCancelAction={() => props.onClose()}
            onAffirmativeAction={() => handleClearSelectedCustomer()}
            affirmativeText={'Clear'}
            affirmativeLoading={loadingClear}
        />
    );
}

ClearSelectedCustomerModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ClearSelectedCustomerModal;
